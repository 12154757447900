import React, { useCallback, useState, useMemo } from 'react';
import { message, FloatButton } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Box from './components/Box';
import MemberCard from './components/MemberCard';
import styles from './app.module.scss';
import { memberData, tactics } from './data/data';
import { skills } from './data/skills';
import { getBuffValue, getPower, getRealValue } from './utils/utils';
import UseSkillAndEnemy from './components/UseSkillAndEnemy';
import BuffCard from './components/BuffCard';
import BottomContent from './components/BottomContent';
import GlobalModal from './components/GlobalModal';

const version = 'v1.04-alpha';
const lastVerison = 'v1.03-alpha';

// 根据对应 members, tactics, category 的 filter
const categoryFilter = (skill, members, tactics, category) =>
    members.includes(skill.memberName) &&
    skill.isBuffSkill &&
    skill.buffParts.some(part => part.buffCategory === category) &&
    (!skill.isPassiveSkill || (skill.isPassiveSkill && tactics.includes(skill.tactic)));

// 对应 members, tactics, category 的 buff 数量
// 用于计算 atkCounts defCounts 等
const getCategoryBuffCounts = (selectedMember, tactics, category) => {
    const filterSkills = skills.filter(skill => categoryFilter(skill, selectedMember, tactics, category));
    let count = filterSkills.length;
    filterSkills.forEach(skill => {
        count += skill.buffParts.filter(part => part.buffCategory === category).length - 1;
    });
    return count;
};

// BuffCards input 回调
const handleBuffInputChange = (
    value,
    index,
    inputIndex,
    counts,
    otherCounts,
    setCounts,
    setOtherCounts,
    setOtherBuffs
) => {
    const allLength = counts.length + (otherCounts ? otherCounts.length : 0);
    // 设置叠加层数
    if (inputIndex === 1) {
        if (!otherCounts || index < allLength - otherCounts.length) {
            setCounts(pre => {
                const newArr = [...pre];
                newArr[index] = value;
                return newArr;
            });
        } else {
            setOtherCounts(pre => {
                const newArr = [...pre];
                newArr[index - counts.length] = value;
                return newArr;
            });
        }
    }
    // 设置 buff 值
    else {
        // 只允许手动更改后面几项
        if (otherCounts && index >= allLength - otherCounts.length) {
            setOtherBuffs(pre => {
                const newArr = [...pre];
                newArr[index - counts.length].value = value;
                return newArr;
            });
        }
    }
};

const App = () => {
    // 攻击手
    const [atkMember, setAtkMember] = useState();
    // 攻击技能
    const [atkSkill, setAtkSkill] = useState();
    const [border, setBorder] = useState(360);
    const [breakNum, setBreakNum] = useState(100);

    // 已选择的队员 name 数组
    const [selectedMember, setSelectMember] = useState([]);
    // 已选择的战术形态
    const [selectedTactics, setSelectedTactics] = useState([]);
    // 队伍数据
    const [abilities, setAbilities] = useState([
        [360, 360, 360, 360, 360, 360, 5],
        [360, 360, 360, 360, 360, 360, 5],
        [360, 360, 360, 360, 360, 360, 5],
        [360, 360, 360, 360, 360, 360, 5],
        [360, 360, 360, 360, 360, 360, 5],
        [360, 360, 360, 360, 360, 360, 5],
    ]);
    // 队伍的所有技能等级
    const [levels, setLevels] = useState([[], [], [], [], [], []]);
    // 工具介绍 modal 是否 open
    const [isGlobalModalOpen, setIsGlobalModalOpen] = useState(localStorage.getItem(version) !== 'true');

    /**
     * 根据选中的队员、七维、技能等级，给出所有可用的增益技能列表
     * 比 skills 表中多了实际的增益值（根据七维、技能等级求得）
     *
     * @param {*} selectedMember    选中队员一维数组
     * @param {*} abilities         能力值二维数组
     * @param {*} skillLevels       技能等级二维数组
     * @returns
     */
    const buffSkills = useMemo(() => {
        // 全部 buff 技能，按照 selectedMember 排序
        const buffSkills = skills
            .filter(skill => {
                return selectedMember.includes(skill.memberName) && skill.isBuffSkill;
            })
            // 只要选中的战术形态的被动技能
            .filter(skill => !skill.isPassiveSkill || (skill.isPassiveSkill && selectedTactics.includes(skill.tactic)))
            // 按照 selectedMember 排序，以便和下面 levels 数组对应
            .sort(
                (a, b) =>
                    selectedMember.findIndex(name => a.memberName === name) -
                    selectedMember.findIndex(name => b.memberName === name)
            );
        // 和 buffSkills 匹配的 levels 数组
        const newSkillLevelsArray = [[], [], [], [], [], []];
        for (let i = 0; i < selectedMember.length; ++i) {
            // 去掉纯攻击技能
            const pureAtkSkillIndex = skills.filter(
                skill =>
                    skill.memberName === selectedMember[i] &&
                    skill.isAtkSkill &&
                    !skill.isBuffSkill &&
                    !skill.isPassiveSkill
            ).length;
            newSkillLevelsArray[i].push(...levels[i].slice(pureAtkSkillIndex));
            // 加上选定战术形态的被动&buff技能，level设为1
            const purePassiveSkillIndex = skills.filter(
                skill =>
                    skill.memberName === selectedMember[i] &&
                    skill.isPassiveSkill &&
                    skill.isBuffSkill &&
                    selectedTactics.includes(skill.tactic)
            ).length;
            newSkillLevelsArray[i].push(...Array(purePassiveSkillIndex).fill(1));
        }

        // 每个 buffSkill 都有对应的 level
        if (buffSkills.length !== newSkillLevelsArray.flat().length) {
            console.log(buffSkills);
            console.log(newSkillLevelsArray);
            message.error('buffSkills array is wrong ...');
        }

        // 添加具体 buff 值 value
        buffSkills.forEach((skill, index) => {
            const { memberName, buffParts } = skill;
            for (let i = 0; i < buffParts.length; ++i) {
                const { buffValue, iZon, threshold, growth, buffCategory, funnelValue } = buffParts[i];
                const [min, max] = buffValue;
                // 计算 buff 技能具体 buff 值
                let value = 0;
                // 连击
                if (buffCategory === 'Funnel') {
                    if (skill.isPassiveSkill) {
                        value = (min / 100) * funnelValue;
                    } else {
                        const memberIndex = selectedMember.findIndex(member => member === memberName);
                        const power = getPower(iZon, abilities[memberIndex]);
                        // 计算 level 下的 buff 值
                        const [realMin, realMax] = getRealValue(min, max, newSkillLevelsArray.flat()[index], growth);
                        value = getBuffValue(realMin, realMax, threshold, power, buffCategory, border, funnelValue);
                    }
                }
                // 被动技能
                else if (min === max) {
                    value = min;
                } else {
                    const memberIndex = selectedMember.findIndex(member => member === memberName);
                    const power = getPower(iZon, abilities[memberIndex]);
                    // 计算 level 下的 buff 值
                    const [realMin, realMax] = getRealValue(min, max, newSkillLevelsArray.flat()[index], growth);
                    value = getBuffValue(realMin, realMax, threshold, power, buffCategory, border, funnelValue);
                    // 计算宝珠加成
                    const hojuCategoryIndex = tactics
                        .find(tactic => tactic.name === memberName)
                        .tactic.findIndex(t => t === selectedTactics[memberIndex]);
                    const hojuCategoryArr = tactics.find(tactic => tactic.name === memberName).hojuCategory;
                    const hojuCategory = hojuCategoryArr[hojuCategoryIndex];
                    // 加攻强化型
                    if (
                        (hojuCategory === 'AttackUp' && buffCategory === 'AttackUp') ||
                        (hojuCategory === 'BuffCharge' && buffCategory === 'BuffCharge')
                    ) {
                        const hojuMin = min * abilities[memberIndex][6] * 0.04;
                        const hojuMax = max * abilities[memberIndex][6] * 0.04;
                        const hojoThreshold = threshold + abilities[memberIndex][6] * 60;
                        value += getBuffValue(hojuMin, hojuMax, hojoThreshold, power, hojuCategory, border);
                    }
                    // 减益提升型
                    // 减防、脆弱的 hojuCategory 都是 DefenseDown
                    else if (
                        (hojuCategory === 'DefenseDown' && buffCategory === 'DefenseDown') ||
                        (hojuCategory === 'DefenseDown' && buffCategory === 'Fragile')
                    ) {
                        const hojuMin = min * abilities[memberIndex][6] * 0.02;
                        const hojuMax = max * abilities[memberIndex][6] * 0.02;
                        const hojoThreshold = threshold + abilities[memberIndex][6] * 20;
                        value += getBuffValue(hojuMin, hojuMax, hojoThreshold, power, hojuCategory, border);
                    }
                }
                buffParts[i].value = value;
            }
        });
        return buffSkills;
    }, [abilities, levels, selectedMember, selectedTactics, border]);

    /**
     * 根据 buffSkills 和 category 再做进一步细分
     * 如果同一 category 的技能包含多个 buff 效果（例如绯雨 - 细雨，包含两种降防效果）
     * 则拆成两个技能，确保返回值中所有 parts.length < 2
     *
     * @param {*} category
     */
    const getDifferentSkills = useCallback(
        categoryArr => {
            const subBuffSkills = buffSkills.filter(skill =>
                skill.buffParts.some(part => categoryArr.includes(part.buffCategory))
            );
            const res = [];
            for (let i = 0; i < subBuffSkills.length; ++i) {
                if (!subBuffSkills[i].buffParts || subBuffSkills[i].buffParts.length < 2) {
                    res.push(subBuffSkills[i]);
                } else {
                    const thisParts = subBuffSkills[i].buffParts.filter(part =>
                        categoryArr.includes(part.buffCategory)
                    );
                    for (let j = 0; j < thisParts.length; ++j) {
                        const newBuffSkill = { ...subBuffSkills[i] };
                        newBuffSkill.buffParts = [{ ...thisParts[j] }];
                        res.push(newBuffSkill);
                    }
                }
            }
            return res;
        },
        [buffSkills]
    );

    // ---------- 攻击增益相关 ----------
    const [atkCounts, setAtkCounts] = useState([]);
    const [otherAtkBuffs, setOtherAtkBuffs] = useState([
        { name: '属性戒指', value: 10, maxCount: 1, min: 0, max: 10 },
        { name: '耳环', value: 15, maxCount: 1, min: 0, max: 15 },
        { name: 'OD', value: 10, maxCount: 1, min: 10, max: 10 },
    ]);
    const [otherAtkCounts, setOtherAtkCounts] = useState([1, 1, 1]);
    // 所有攻击增益技能
    const atkBuffSkills = useMemo(() => getDifferentSkills(['AttackUp', 'BuffCharge']), [getDifferentSkills]);
    // 攻击增益总倍率
    const allAtkValue =
        atkBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * atkCounts[index], 0) +
        otherAtkBuffs.reduce((pre, cur, index) => pre + cur.value * otherAtkCounts[index], 0) +
        100;

    // ---------- 防御增益相关 ----------
    const [defCounts, setDefCounts] = useState([]);
    // 所有防御增益技能
    const defBuffSkills = useMemo(() => getDifferentSkills(['DefenseDown']), [getDifferentSkills]);
    // 防御增益总倍率
    const allDefValue =
        defBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * defCounts[index], 0) + 100;

    // ---------- 暴击增益相关 ----------
    const [critCounts, setCritCounts] = useState([]);
    // 所有暴击增益技能
    const critBuffSkills = useMemo(() => getDifferentSkills(['CriticalDamageUp']), [getDifferentSkills]);
    // 暴击增益总倍率
    const allCritValue =
        critBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * critCounts[index], 0) + 150;

    // ---------- 属性增益相关 ----------
    const [otherWeakBuffs, setOtherWeakBuffs] = useState([
        { name: '物理弱点', value: 0, maxCount: 1, min: -99, max: 999 },
        { name: '元素弱点', value: 0, maxCount: 1, min: -99, max: 999 },
    ]);
    const [otherWeakCounts, setOtherWeakCounts] = useState([1, 1]);
    // 属性增益总倍率
    const allWeakValue =
        otherWeakBuffs.reduce((pre, cur, index) => pre * Math.pow(cur.value / 100 + 1, otherWeakCounts[index]), 1) *
        100;

    // ---------- 心眼 ----------
    const [mindCounts, setMindCounts] = useState([]);
    // 所有心眼增益技能
    const mindBuffSkills = useMemo(() => getDifferentSkills(['MindEye']), [getDifferentSkills]);
    // 心眼增益总倍率
    const allMindValue =
        mindBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * mindCounts[index], 0) + 100;

    // ---------- 脆弱 ----------
    const [fragileCounts, setFragileCounts] = useState([]);
    // 所有脆弱增益技能
    const fragileBuffSkills = useMemo(() => getDifferentSkills(['Fragile']), [getDifferentSkills]);
    // 脆弱增益总倍率
    const allFragileValue =
        fragileBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * fragileCounts[index], 0) + 100;

    // ---------- 连击增益 ----------
    const [funnelCounts, setFunnelCounts] = useState([]);
    // 所有连击增益技能
    const funnelBuffSkills = useMemo(() => getDifferentSkills(['Funnel']), [getDifferentSkills]);
    // 连击增益总倍率
    const allFunnelValue =
        funnelBuffSkills.reduce((pre, cur, index) => pre + cur.buffParts[0].value * funnelCounts[index], 0) + 100;

    // ---------- 其他增益相关（领域、token） ----------
    const [oCounts, setOCounts] = useState([]);
    const [otherOBuffs, setOtherOBuffs] = useState([{ name: 'Token', value: 16, maxCount: 10, min: 16, max: 16 }]);
    const [otherOCounts, setOtherOCounts] = useState([0]);
    // 所有其他增益技能
    const oBuffSkills = useMemo(() => getDifferentSkills(['Zone']), [getDifferentSkills]);
    // 其他增益总倍率
    const allOValue =
        oBuffSkills.reduce((pre, cur, index) => pre * ((cur.buffParts[0].value * oCounts[index] + 100) / 100), 1) *
        (otherOBuffs.reduce((pre, cur, index) => pre + cur.value * otherOCounts[index], 0) + 100);

    // 七维输入框回调
    // index        七维index 0 - 6
    // memberIndex  队员index 0 - 5
    const handleAbilityChange = useCallback(
        (val, index, memberIndex) => {
            const array = abilities[memberIndex];
            const newArray = [...array];
            // 六维默认值1，宝珠默认值0
            newArray[index] = val || (index === 6 ? 0 : 1);
            // 重设七维数组
            setAbilities(pre => {
                const newAbilities = [...pre];
                newAbilities[memberIndex] = newArray;
                return newAbilities;
            });
        },
        [abilities]
    );

    // 战术形态选择回调
    const handleTacticChange = useCallback(
        (value, memberIndex) => {
            // 更新 selectedTactics
            const newTactics = [...selectedTactics];
            newTactics[memberIndex] = value;
            setSelectedTactics(newTactics);
            // 设置 counts，仅重置当前队员的 counts
            const addMemberArr = [selectedMember[memberIndex]],
                addTacticArr = [value];
            setAtkCounts(pre => {
                const res = [...pre];
                const count =
                    getCategoryBuffCounts(addMemberArr, addTacticArr, 'AttackUp') +
                    getCategoryBuffCounts(addMemberArr, addTacticArr, 'BuffCharge');
                res.splice(
                    atkBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    atkBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setDefCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'DefenseDown');
                res.splice(
                    defBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    defBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setCritCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'CriticalDamageUp');
                res.splice(
                    critBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    critBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setMindCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'MindEye');
                res.splice(
                    mindBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    mindBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setFragileCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'Fragile');
                res.splice(
                    fragileBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    fragileBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setFunnelCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'Funnel');
                res.splice(
                    funnelBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    funnelBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
            setOCounts(pre => {
                const res = [...pre];
                const count = getCategoryBuffCounts(addMemberArr, addTacticArr, 'Zone');
                res.splice(
                    oBuffSkills.findIndex(skill => skill.memberName === selectedMember[memberIndex]),
                    oBuffSkills.filter(skill => skill.memberName === selectedMember[memberIndex]).length,
                    ...Array(count).fill(0)
                );
                return res;
            });
        },
        [
            atkBuffSkills,
            critBuffSkills,
            defBuffSkills,
            fragileBuffSkills,
            funnelBuffSkills,
            mindBuffSkills,
            oBuffSkills,
            selectedMember,
            selectedTactics,
        ]
    );

    // 技能等级输入框回调函数
    const handleLevelChange = useCallback((value, memberIndex, index) => {
        setLevels(pre => {
            const newLevels = [...pre];
            newLevels[memberIndex][index] = value || 1;
            return newLevels;
        });
    }, []);

    // 渲染队伍
    const renderTeam = useCallback(() => {
        return selectedMember.map((name, index) => {
            return (
                <MemberCard
                    name={name}
                    abilities={abilities[index]}
                    handleInputChange={handleAbilityChange}
                    handleTacticChange={handleTacticChange}
                    memberIndex={index}
                    levels={levels[index]}
                    handleLevelChange={handleLevelChange}
                    tactic={selectedTactics[index]}
                    key={index}
                />
            );
        });
    }, [
        selectedMember,
        abilities,
        selectedTactics,
        levels,
        handleLevelChange,
        handleAbilityChange,
        handleTacticChange,
    ]);

    // 单个 box checkbox 回调
    const handleSingleChange = e => {
        const { checked, value } = e.target;
        let newSelectMember = [],
            newTactics = [];
        // 队员增加时
        if (checked) {
            // 设置默认攻击技能为第一个人的第一个攻击技能
            if (selectedMember.length === 0) {
                setAtkMember(value);
                const atkSkills = skills.filter(skill => skill.memberName === value && skill.isAtkSkill);
                setAtkSkill(atkSkills.length ? atkSkills?.[0].name : undefined);
            }
            // 新增的队员七维设为初始值
            setAbilities(pre => {
                const newAbilities = [...pre];
                newAbilities[selectedMember.length] = [360, 360, 360, 360, 360, 360, 5];
                return newAbilities;
            });
            // 新增的队员所有技能初始值
            setLevels(pre => {
                const newLevels = [...pre];
                // 当前队员的所有非被动技能 默认最高等级
                const allSkills = skills.filter(skill => skill.memberName === value && !skill.isPassiveSkill);
                newLevels[selectedMember.length] = allSkills.map(skill => skill.level[1]);
                return newLevels;
            });
            // 更新选中的队员
            newSelectMember = [...selectedMember, value];
            setSelectMember(newSelectMember);
            // 更新战术形态
            newTactics = [...selectedTactics, tactics.find(tactic => tactic.name === value).tactic[0]];
            setSelectedTactics(newTactics);
            // 设置 counts
            const addMemberArr = [value],
                addTacticArr = [tactics.find(tactic => tactic.name === value).tactic[0]];
            setAtkCounts(pre => [
                ...pre,
                ...Array(
                    getCategoryBuffCounts(addMemberArr, addTacticArr, 'AttackUp') +
                        getCategoryBuffCounts(addMemberArr, addTacticArr, 'BuffCharge')
                ).fill(0),
            ]);
            setDefCounts(pre => [
                ...pre,
                ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'DefenseDown')).fill(0),
            ]);
            setCritCounts(pre => [
                ...pre,
                ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'CriticalDamageUp')).fill(0),
            ]);
            setMindCounts(pre => [
                ...pre,
                ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'MindEye')).fill(0),
            ]);
            setFragileCounts(pre => [
                ...pre,
                ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'Fragile')).fill(0),
            ]);
            setFunnelCounts(pre => [
                ...pre,
                ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'Funnel')).fill(0),
            ]);
            setOCounts(pre => [...pre, ...Array(getCategoryBuffCounts(addMemberArr, addTacticArr, 'Zone')).fill(0)]);
        }
        // 队员减少时
        else {
            // 更新攻击手、攻击技能
            const removeIndex = selectedMember.findIndex(member => member === value);
            const i = removeIndex === 0 ? 1 : 0;
            setAtkMember(selectedMember?.[i]);
            const atkSkills = skills.filter(skill => skill.memberName === selectedMember?.[i] && skill.isAtkSkill);
            setAtkSkill(atkSkills.length ? atkSkills?.[0].name : undefined);
            // 更新所有人的七维，依次前移
            const index = selectedMember.findIndex(member => member === value);
            setAbilities(pre => {
                const newAbilities = [...pre];
                for (let i = index; i < 5; ++i) {
                    newAbilities[i] = [...newAbilities[i + 1]];
                }
                newAbilities[5] = [360, 360, 360, 360, 360, 360, 5];
                return newAbilities;
            });
            // 更新所有人的技能等级，依次前移
            setLevels(pre => {
                const newLevels = [...pre];
                for (let i = index; i < 5; ++i) {
                    newLevels[i] = [...newLevels[i + 1]];
                }
                newLevels[5] = [];
                return newLevels;
            });
            // 更新选中的队员
            newSelectMember = [
                ...selectedMember.slice(0, index),
                ...selectedMember.slice(index + 1, selectedMember.length),
            ];
            setSelectMember(newSelectMember);
            // 更新战术形态
            newTactics = [
                ...selectedTactics.slice(0, index),
                ...selectedTactics.slice(index + 1, selectedMember.length),
            ];
            setSelectedTactics(newTactics);
            // 设置 counts
            setAtkCounts(pre => {
                const res = [...pre];
                res.splice(
                    atkBuffSkills.findIndex(skill => skill.memberName === value),
                    atkBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
            setDefCounts(pre => {
                const res = [...pre];
                res.splice(
                    defBuffSkills.findIndex(skill => skill.memberName === value),
                    defBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
            setMindCounts(pre => {
                const res = [...pre];
                res.splice(
                    mindBuffSkills.findIndex(skill => skill.memberName === value),
                    mindBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
            setFragileCounts(pre => {
                const res = [...pre];
                res.splice(
                    fragileBuffSkills.findIndex(skill => skill.memberName === value),
                    fragileBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
            setFunnelCounts(pre => {
                const res = [...pre];
                res.splice(
                    funnelBuffSkills.findIndex(skill => skill.memberName === value),
                    funnelBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
            setOCounts(pre => {
                const res = [...pre];
                res.splice(
                    oBuffSkills.findIndex(skill => skill.memberName === value),
                    oBuffSkills.filter(skill => skill.memberName === value).length
                );
                return res;
            });
        }
    };

    // 攻击增益输入框回调
    const handleAtkInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(
            value,
            index,
            inputIndex,
            atkCounts,
            otherAtkCounts,
            setAtkCounts,
            setOtherAtkCounts,
            setOtherAtkBuffs
        );
    };

    // 防御增益输入框回调
    const handleDefInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(value, index, inputIndex, defCounts, undefined, setDefCounts);
    };

    // 暴击增益输入框回调
    const handleCritInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(value, index, inputIndex, critCounts, undefined, setCritCounts);
    };

    // 属性克制增益输入框回调
    const handleWeakInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(
            value,
            index,
            inputIndex,
            [],
            otherWeakCounts,
            () => {},
            setOtherWeakCounts,
            setOtherWeakBuffs
        );
    };

    // 心眼增益输入框回调
    const handleMindInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(value, index, inputIndex, mindCounts, undefined, setMindCounts);
    };

    // 脆弱增益输入框回调
    const handleFragileInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(value, index, inputIndex, fragileCounts, undefined, setFragileCounts);
    };

    // 连击增益输入框回调
    const handleFunnelInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(value, index, inputIndex, funnelCounts, undefined, setFunnelCounts);
    };

    // 其他增益输入框回调
    const handleOInputChange = (value, index, inputIndex) => {
        handleBuffInputChange(
            value,
            index,
            inputIndex,
            oCounts,
            otherOCounts,
            setOCounts,
            setOtherOCounts,
            setOtherOBuffs
        );
    };

    // 最终 damage 数据
    const damage = useMemo(() => {
        if (atkSkill && atkMember) {
            const skill = skills.find(skill => skill.name === atkSkill && skill.memberName === atkMember);
            const memberIndex = selectedMember.findIndex(member => member === atkMember);
            const { iZon, growth, buffValue, threshold, multipliers } = skill.atkPart;
            const [min, max] = buffValue;
            const power = getPower(iZon, abilities[memberIndex]);
            // 计算 level 下的 buff 值
            const levelIndex = skills
                .filter(skill => skill.memberName === atkMember)
                .findIndex(skill => skill.name === atkSkill);
            const [realMin, realMax] = getRealValue(min, max, levels[memberIndex][levelIndex], growth);
            let critValue = getBuffValue(realMin, realMax, threshold, power, 'Attack', border, 4, true);
            let noCritValue = getBuffValue(realMin, realMax, threshold, power, 'Attack', border, 4, false);

            // 计算宝珠加成
            const hojuCategoryIndex = tactics
                .find(tactic => tactic.name === atkMember)
                .tactic.findIndex(t => t === selectedTactics[memberIndex]);
            const hojuCategoryArr = tactics.find(tactic => tactic.name === atkMember).hojuCategory;
            const hojuCategory = hojuCategoryArr[hojuCategoryIndex];
            if (hojuCategory === 'Attack') {
                const hojuMin = min * abilities[memberIndex][6] * 0.02;
                const hojuMax = max * abilities[memberIndex][6] * 0.02;
                const hojoThreshold = threshold + abilities[memberIndex][6] * 20;
                critValue += getBuffValue(hojuMin, hojuMax, hojoThreshold, power, hojuCategory, border, 4, true);
                noCritValue += getBuffValue(hojuMin, hojuMax, hojoThreshold, power, hojuCategory, border, 4, false);
            }
            critValue *=
                (allAtkValue *
                    allDefValue *
                    allCritValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue) /
                Math.pow(100, 8);
            noCritValue *=
                (allAtkValue *
                    allDefValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue) /
                Math.pow(100, 7);
            return [
                critValue * multipliers.dp,
                (critValue * multipliers.hp * breakNum) / 100,
                noCritValue * multipliers.dp,
                (noCritValue * multipliers.hp * breakNum) / 100,
                (allAtkValue *
                    allDefValue *
                    allCritValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue *
                    multipliers.dp) /
                    Math.pow(100, 8),
                (allAtkValue *
                    allDefValue *
                    allCritValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue *
                    breakNum *
                    multipliers.hp) /
                    Math.pow(100, 9),
                (allAtkValue *
                    allDefValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue *
                    multipliers.dp) /
                    Math.pow(100, 7),
                (allAtkValue *
                    allDefValue *
                    allWeakValue *
                    allMindValue *
                    allFragileValue *
                    allFunnelValue *
                    allOValue *
                    breakNum *
                    multipliers.hp) /
                    Math.pow(100, 8),
            ];
        }
        return [0, 0, 0, 0, 0, 0, 0, 0];
    }, [
        abilities,
        allAtkValue,
        allCritValue,
        allDefValue,
        allFunnelValue,
        allOValue,
        allWeakValue,
        allMindValue,
        allFragileValue,
        atkMember,
        atkSkill,
        border,
        breakNum,
        levels,
        selectedMember,
        selectedTactics,
    ]);

    return (
        <div className={styles.app}>
            {/* {JSON.stringify(selectedMember)}
            <hr />
            {JSON.stringify(selectedTactics)}
            <hr />
            {'技能等级：' + JSON.stringify(levels)}
            <hr />
            {'七维：' + JSON.stringify(abilities)}
            <hr />
            {border}
            <hr />
            {breakNum} */}
            <div className={styles.line}>
                <UseSkillAndEnemy
                    skills={skills.filter(skill => selectedMember.includes(skill.memberName) && skill.isAtkSkill)}
                    selectedMembers={selectedMember}
                    atkMember={atkMember}
                    atkSkill={atkSkill}
                    border={border}
                    breakNum={breakNum}
                    handleMemberChange={value => {
                        setAtkMember(value);
                        const atkSkills = skills.filter(skill => skill.memberName === value && skill.isAtkSkill);
                        setAtkSkill(atkSkills.length ? atkSkills?.[0].name : undefined);
                    }}
                    handleSkillChange={value => {
                        setAtkSkill(value);
                    }}
                    handleBorderChange={value => setBorder(value)}
                    handleBreakNumChange={value => setBreakNum(value)}
                />
                <Box memberData={memberData} selectedMember={selectedMember} handleSingleChange={handleSingleChange} />
            </div>
            {/* 已选择的队员 */}
            <div className={styles.team}>{renderTeam()}</div>
            {/* 各种修正 */}
            <div className={styles.gird}>
                {/* 攻击修正 */}
                <BuffCard
                    title="攻击修正"
                    buffSkills={[...atkBuffSkills, ...otherAtkBuffs]}
                    counts={[...atkCounts, ...otherAtkCounts]}
                    allValue={allAtkValue}
                    handleInputChange={handleAtkInputChange}
                />
                {/* 防御修正 */}
                <BuffCard
                    title="防御修正"
                    buffSkills={[...defBuffSkills]}
                    counts={[...defCounts]}
                    allValue={allDefValue}
                    handleInputChange={handleDefInputChange}
                />
                {/* 暴击修正 */}
                <BuffCard
                    title="暴击修正"
                    buffSkills={[...critBuffSkills]}
                    counts={[...critCounts]}
                    allValue={allCritValue}
                    handleInputChange={handleCritInputChange}
                />
                {/* 属性修正 */}
                <BuffCard
                    title="克制修正"
                    buffSkills={[...otherWeakBuffs]}
                    counts={[...otherWeakCounts]}
                    allValue={allWeakValue}
                    handleInputChange={handleWeakInputChange}
                />
                {/* 心眼修正 */}
                <BuffCard
                    title="心眼修正"
                    buffSkills={[...mindBuffSkills]}
                    counts={[...mindCounts]}
                    allValue={allMindValue}
                    handleInputChange={handleMindInputChange}
                />
                {/* 脆弱修正 */}
                <BuffCard
                    title="脆弱修正"
                    buffSkills={[...fragileBuffSkills]}
                    counts={[...fragileCounts]}
                    allValue={allFragileValue}
                    handleInputChange={handleFragileInputChange}
                />
                {/* 连击修正 */}
                <BuffCard
                    title="连击修正"
                    buffSkills={[...funnelBuffSkills]}
                    counts={[...funnelCounts]}
                    allValue={allFunnelValue}
                    handleInputChange={handleFunnelInputChange}
                />
                {/* 其他修正 */}
                <BuffCard
                    title="其他修正（领域、Token）"
                    buffSkills={[...oBuffSkills, ...otherOBuffs]}
                    counts={[...oCounts, ...otherOCounts]}
                    allValue={allOValue}
                    handleInputChange={handleOInputChange}
                />
            </div>
            <BottomContent damageData={damage} />
            <FloatButton
                icon={<QuestionCircleOutlined />}
                type="primary"
                style={{ left: '1.5rem', bottom: '20px' }}
                onClick={() => setIsGlobalModalOpen(true)}
            />
            <GlobalModal
                isOpen={isGlobalModalOpen}
                handleModalOpen={() => setIsGlobalModalOpen(true)}
                handleModalClose={() => {
                    localStorage.setItem(version, true);
                    localStorage.removeItem(lastVerison);
                    setIsGlobalModalOpen(false);
                }}
            />
        </div>
    );
};

export default App;
