import React from 'react';
import styles from './index.module.scss';

const BottomContent = props => {
    const { damageData } = props;
    const [dpDamageCrit, hpDamageCrit, dpDamage, hpDamage, dpRatioCrit, hpRatioCrit, dpRatio, hpRatio] = damageData;

    return (
        <div className={styles.content}>
            <div className={styles.box}>
                <div className={styles.p}>
                    （全暴击）对 dp 伤害({(dpRatioCrit * 100).toFixed(0)}%)： {Math.round(dpDamageCrit)}(
                    {Math.round(dpDamageCrit * 0.9)} - {Math.round(dpDamageCrit * 1.1)})
                </div>
                <div className={styles.p}>
                    （非暴击）对 dp 伤害({(dpRatio * 100).toFixed(0)}%)： {Math.round(dpDamage)}(
                    {Math.round(dpDamage * 0.9)} - {Math.round(dpDamage * 1.1)})
                </div>
                <div className={styles.p}>
                    （全暴击）对 hp 伤害({(hpRatioCrit * 100).toFixed(0)}%)： {Math.round(hpDamageCrit)}(
                    {Math.round(hpDamageCrit * 0.9)} - {Math.round(hpDamageCrit * 1.1)})
                </div>
                <div className={styles.p}>
                    （非暴击）对 hp 伤害({(hpRatio * 100).toFixed(0)}%)： {Math.round(hpDamage)}(
                    {Math.round(hpDamage * 0.9)} - {Math.round(hpDamage * 1.1)})
                </div>
            </div>
        </div>
    );
};

export default BottomContent;
