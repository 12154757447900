import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import styles from './index.module.scss';

// memberData           所有成员
// selectedMember       选中的队员
// handleSingleChange   单个 checkbox change 回调函数
const Box = props => {
    const { memberData, selectedMember, handleSingleChange } = props;

    // 渲染所有成员
    const getCols = () => {
        return memberData.map((value, index) => {
            const { name } = value;
            // 最多选中6个
            const getDisabled = () => {
                if (selectedMember.length !== 6) {
                    return false;
                }
                return !selectedMember.includes(name);
            };
            return (
                <Col span={4} key={index}>
                    <Checkbox value={name} disabled={getDisabled()} onChange={handleSingleChange}>
                        {name}
                    </Checkbox>
                </Col>
            );
        });
    };

    return (
        <div className={styles.box}>
            <p>选择队伍</p>
            <Checkbox.Group className={styles.checkbox}>
                <Row>{getCols()}</Row>
            </Checkbox.Group>
        </div>
    );
};

export default Box;
