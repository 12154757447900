import { memberData } from './data';

// 所有技能，只包含攻击技能、buff技能（对伤害有影响的技能）、被动技能
// 内部按人员排序，同一人员，按纯攻击技能、攻击&buff技能、纯buff技能、被动技能排序
export const skills = [
    {
        memberName: '茅森月歌',
        name: '雷霆脈衝',
        tactic: 'ナイトクルーズ・エスコート',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '烈焰衝擊',
        tactic: 'つかの間の安息',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '萬紫千紅',
        tactic: '残響のカルディナル',
        hit: 12,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '貴族之楔',
        tactic: '残響のカルディナル',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 8,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '星火燎原',
        tactic: '黎明のエモーショナル・ソウル',
        hit: 2,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '星火燎原+（3队友以上）',
        tactic: '黎明のエモーショナル・ソウル',
        hit: 2,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [60, 75],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '星火燎原+（3队友未满）',
        tactic: '黎明のエモーショナル・ソウル',
        hit: 2,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [60, 75],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '夢幻泡影',
        tactic: '閃光のサーキットバースト',
        hit: 9,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1602, 8010],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.75,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '全力破壞斬',
        tactic: '戦場のフレット',
        hit: 2,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '迴旋斬',
        tactic: '黎明のエモーショナル・ソウル',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '十字斬',
        tactic: 'Attack or Music',
        hit: 2,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '迅雷風烈',
        tactic: 'ナイトクルーズ・エスコート',
        hit: 5,
        sp: 16,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 149,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '黑曜君王',
        tactic: 'The Feel of the Throne',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 143,
                buffCategory: 'DefenseDown',
                elements: ['Dark', 'Fire'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 143,
            buffValue: [2025, 10125],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.125,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '黑曜君王（弱点时）',
        tactic: 'The Feel of the Throne',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 143,
                buffCategory: 'DefenseDown',
                elements: ['Dark', 'Fire'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 143,
            buffValue: [2673, 13365],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.125,
            },
        },
    },
    {
        memberName: '茅森月歌',
        name: '炯眼模式',
        tactic: 'The Feel of the Throne',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '雷の強威',
        tactic: 'ナイトクルーズ・エスコート',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '雷の波動',
        tactic: 'ナイトクルーズ・エスコート',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '危険な火遊び',
        tactic: 'つかの間の安息',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [50, 50],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '英雄の凱歌',
        tactic: '黎明のエモーショナル・ソウル',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '勇猛',
        tactic: '閃光のサーキットバースト',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '疾風',
        tactic: 'Attack or Music',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '心眼の境地',
        tactic: 'The Feel of the Throne',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '万物の強威',
        tactic: 'The Feel of the Throne',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '流星+',
        tactic: '終いのSpitfire',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '光輝耀眼的黎明',
        tactic: '踊るリンカネーション',
        hit: 3,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042.25, 10211.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '少女的光輝',
        tactic: '踊るリンカネーション',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.25,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '赤紅行動',
        tactic: 'KETSUの休日',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '突波高電壓',
        tactic: 'ナイトクルーズ・アテンダント',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '牡丹',
        tactic: '夏椿、けうらなる夜光星',
        hit: 6,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '流星',
        tactic: '終いのSpitfire',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '銀蜂',
        tactic: '夏椿、けうらなる夜光星',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '毀滅加農炮',
        tactic: '終いのSpitfire',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '毀滅爆裂炮',
        tactic: 'Attack or March',
        hit: 3,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [294, 1470],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 0.8,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '流星+（首次）',
        tactic: '終いのSpitfire',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '超大胞雷暴',
        tactic: 'ナイトクルーズ・アテンダント',
        hit: 5,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 150,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2175, 10875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '冷卻',
        tactic: '夢幻のSleeping Ocelot',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '面具圆舞曲',
        tactic: '君を待つ紅玉',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '少女的秘密',
        tactic: '君を待つ紅玉',
        hit: 5,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 65],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Dark'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '和泉由希',
        name: '突の躍動',
        tactic: 'KETSUの休日',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [8, 8],
                buffCategory: 'CriticalDamageUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '烈風',
        tactic: 'KETSUの休日',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '決心',
        tactic: 'ナイトクルーズ・アテンダント',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '雷の波動',
        tactic: 'ナイトクルーズ・アテンダント',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '火の強威',
        tactic: '夏椿、けうらなる夜光星',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '決心',
        tactic: '終いのSpitfire',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '疾風',
        tactic: '夢幻のSleeping Ocelot',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '勇気',
        tactic: 'Attack or March',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '浄化の喝采',
        tactic: '君を待つ紅玉',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '破砕の喝采',
        tactic: '君を待つ紅玉',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '極限衝擊+',
        tactic: 'Ikki Burst Strike',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '黃昏',
        tactic: 'トワイライト・グロース',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '英勇直擊',
        tactic: '一夜の夢',
        hit: 5,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2175, 10875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '極限衝擊',
        tactic: 'Ikki Burst Strike',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '全力猛揮',
        tactic: 'Ikki Burst Strike',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '昏厥硬刃',
        tactic: 'Plain or Wild',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '音樂排行榜上的一閃',
        tactic: 'トワイライト・グロース',
        hit: 3,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 40],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 144,
                buffCategory: 'DefenseDown',
                elements: ['Dark'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 144,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '神樂淨火‧閃',
        tactic: '心、躍るFuel',
        hit: 5,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'DefenseDown',
                elements: ['Fire'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [65, 65],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Fire'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '冷卻',
        tactic: '心、躍るFuel',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '赤裸毀滅者',
        tactic: '一夜の夢',
        hit: 1,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 132,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: '剛強硬刃',
        tactic: 'Impact Stream',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '逢川惠',
        name: 'サイキックハイ',
        tactic: 'トワイライト・グロース',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [50, 50],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '闇の波動',
        tactic: 'トワイライト・グロース',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '弱化',
        tactic: '心、躍るFuel',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '火の波動',
        tactic: '心、躍るFuel',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '野生の勘',
        tactic: '一夜の夢',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '勇猛',
        tactic: 'Impact Stream',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '东城司',
        name: '絲質火光',
        tactic: '真夏のPrayer',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '东城司',
        name: '吐息彈',
        tactic: 'メメント・モリの美少女',
        hit: 1,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '东城司',
        name: '蜃景中的淑女',
        tactic: 'シークレットサービス・サイレンス',
        hit: 5,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 30],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [65, 65],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Light'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '东城司',
        name: '神聖增強',
        tactic: 'シークレットサービス・サイレンス',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '心願成真',
        tactic: '真夏のPrayer',
        hit: -1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 240,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '勿忘你終有一死',
        tactic: 'メメント・モリの美少女',
        hit: 3,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Fire'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '东城司',
        name: '勿忘你終有一死+',
        tactic: 'メメント・モリの美少女',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Fire'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '东城司',
        name: '飽和增強',
        tactic: '嗟歎のスリーパー',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '增強',
        tactic: 'Serious or Stupid',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '光の波動',
        tactic: 'シークレットサービス・サイレンス',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '东城司',
        name: '英雄の凱歌',
        tactic: '真夏のPrayer',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '东城司',
        name: '灼熱の陣',
        tactic: 'メメント・モリの美少女',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'Zone',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '殘焰',
        tactic: '狂悖暴戻のランダムウォーク',
        hit: 4,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 8,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '殺啊殺啊殺光光',
        tactic: 'シークレットサービス・デモリッシュ',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 7.2,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '劫火絢爛',
        tactic: 'スカーレット・リベリオン',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 16.8,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '血腥之舞',
        tactic: '紅蓮月華のKillrazor',
        hit: 10,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 13,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '憤怒衝動',
        tactic: 'スカーレット・リベリオン',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '瘋狂亂舞',
        tactic: '最前線のブルワーク',
        hit: 4,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 9,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '狂野投擲',
        tactic: '紅蓮月華のKillrazor',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 5.6,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '亂舞',
        tactic: 'Laugh or Cry',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 5,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '破壞式犬儒主義',
        tactic: 'シークレットサービス・デモリッシュ',
        hit: 5,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042.25, 10211.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '朝仓可怜',
        name: '決意',
        tactic: '狂悖暴戻のランダムウォーク',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '光の強威',
        tactic: 'シークレットサービス・デモリッシュ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '光の波動',
        tactic: 'シークレットサービス・デモリッシュ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '狂乱の型',
        tactic: '紅蓮月華のKillrazor',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [300, 300],
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '勇猛',
        tactic: '最前線のブルワーク',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '稜鏡特攻',
        tactic: '幻想のコーラル',
        hit: 4,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '国见玉',
        name: '熾焰暴風雨',
        tactic: '魔法の国のエレメンタル',
        hit: 4,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '国见玉',
        name: '鬥氣斬',
        tactic: '気合一閃エンジェルセイラー',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '国见玉',
        name: '讓道宣言',
        tactic: 'トワイライト・メモリーズ',
        hit: -1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 240,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '忠誠',
        tactic: 'トワイライト・メモリーズ',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '二律背反火花撥弦',
        tactic: '激突！！エア・ベース',
        hit: 3,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '国见玉',
        name: '復甦之紗',
        tactic: '魔法の国のエレメンタル',
        hit: -1,
        sp: 11,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '万物の波動',
        tactic: 'トワイライト・メモリーズ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [8, 8],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '光の波動',
        tactic: '幻想のコーラル',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '雷の采配',
        tactic: '激突！！エア・ベース',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '英雄の凱歌',
        tactic: '魔法の国のエレメンタル',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '偶像微笑（弱点时）',
        tactic: 'キララ・究極のアイドル',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '苍井绘梨花',
        name: '偶像微笑',
        tactic: 'キララ・究極のアイドル',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '苍井绘梨花',
        name: '青春色彩的軌跡',
        tactic: 'トドケ・Miracle',
        hit: 3,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2178, 10890],
            iZon: {
                str: 0,
                wis: 0,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 1,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '苍井绘梨花',
        name: '護盾龍捲風',
        tactic: 'ココロ・Inspire',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '苍井绘梨花',
        name: '護盾光線',
        tactic: '戦場の花散らし',
        hit: 3,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '苍井绘梨花',
        name: '高揚',
        tactic: 'トドケ・Miracle',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 10,
                buffValue: [5, 5],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '雷の波動',
        tactic: 'トドケ・Miracle',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '決意',
        tactic: '戦場の花散らし',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '三角火光彈',
        tactic: '嬉々迫るフォール・イン・ラヴ',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '蒼天慈悲',
        tactic: 'あなたのために',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '殺手彈',
        tactic: '嬉々迫るフォール・イン・ラヴ',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '炮彈',
        tactic: '戦場の華火',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '三角火光彈+（嘲讽中）',
        tactic: '嬉々迫るフォール・イン・ラヴ',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '三角火光彈+',
        tactic: '嬉々迫るフォール・イン・ラヴ',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '喧嚷的依託',
        tactic: '君の瞳にコロしてる',
        hit: 3,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '水濑莓',
        name: '自我熱忱',
        tactic: '君の瞳にコロしてる',
        hit: -1,
        sp: 8,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 224,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [500, 500],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '火の強威',
        tactic: '君の瞳にコロしてる',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '火炎の極意',
        tactic: '君の瞳にコロしてる',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '雷鳴の陣',
        tactic: '嬉々迫るフォール・イン・ラヴ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'Zone',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '突の威圧',
        tactic: 'あなたのために',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '決心',
        tactic: 'あなたのために',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '勇気',
        tactic: '戦場の華火',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '冰雹風暴',
        tactic: '茹だるアサシン',
        hit: 4,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042.25, 10211.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '三角火光刃',
        tactic: '残光',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '悠游鯊魚',
        tactic: '茹だるアサシン',
        hit: 4,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '風斬之刃',
        tactic: '残光',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '殘影',
        tactic: '戦場の焔焔',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '三角火光刃+（嘲讽中）',
        tactic: '残光',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '三角火光刃+',
        tactic: '残光',
        hit: 1,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '殘心擊',
        tactic: '積乱雲',
        hit: 4,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 126,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '水濑李',
        name: '逗猫棒',
        tactic: '類は友を呼ぶ',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '決心',
        tactic: '茹だるアサシン',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '野生の勘',
        tactic: '残光',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '決心',
        tactic: '積乱雲',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '決心',
        tactic: '類は友を呼ぶ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '勇気',
        tactic: '戦場の焔焔',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '幻想',
        tactic: '青春の発露',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '樋口圣华',
        name: '循環再造',
        tactic: '或る少女の物語',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '樋口圣华',
        name: '絕對現象',
        tactic: '宙の探究、星の眩耀',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [30, 30],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '飽和增強',
        tactic: '宙の探究、星の眩耀',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '詭騙加農炮',
        tactic: '生者のホメオスタシス',
        hit: 1,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 138,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Thunder'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1602, 8010],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.75,
            },
        },
    },
    {
        memberName: '樋口圣华',
        name: '禁藥',
        tactic: '生者のホメオスタシス',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '增強',
        tactic: '戦場の科学者',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '火の慧眼',
        tactic: '青春の発露',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'CriticalDamageUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '詛咒之鐮',
        tactic: '終劇のナイトフォール',
        hit: 4,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '柊木梢',
        name: '迷霧斬',
        tactic: '蒼きノクターン',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '柊木梢',
        name: '迷霧之鐮',
        tactic: '戦場の聳動',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '柊木梢',
        name: '急凍咒語',
        tactic: '終劇のナイトフォール',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '柊木梢',
        name: '迷霧刻劃',
        tactic: '在りし日の雑踏',
        hit: 4,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 126,
                buffCategory: 'DefenseDown',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '柊木梢',
        name: '月光+',
        tactic: '蒼きノクターン',
        hit: -1,
        sp: 13,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 264,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '专一微笑',
        tactic: 'ホップ・ステップ・スリップ！',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 30],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '氷の強威',
        tactic: '終劇のナイトフォール',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '雷の意志',
        tactic: '在りし日の雑踏',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '白虎',
        name: '破壞女王（弱点时）',
        tactic: 'のもけいしさや',
        hit: 9,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2850, 14250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '白虎',
        name: '破壞女王',
        tactic: 'のもけいしさや',
        hit: 9,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '白虎',
        name: '豪傑傲氣',
        tactic: 'のもけいしさや',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 7.2,
            },
        },
    },
    {
        memberName: '白虎',
        name: '野獸牢獄',
        tactic: 'レイジング・ビースト',
        hit: 11,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '白虎',
        name: '狂怒之爪',
        tactic: 'レイジング・ビースト',
        hit: 5,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 16.666666,
            },
        },
    },
    {
        memberName: '白虎',
        name: '純真',
        tactic: 'リラックスサイン',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '白虎',
        name: '大劃之爪',
        tactic: '戦場の哮り',
        hit: 2,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4.8,
            },
        },
    },
    {
        memberName: '白虎',
        name: '雷の強威',
        tactic: 'のもけいしさや',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '白虎',
        name: '野生の勘',
        tactic: 'レイジング・ビースト',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '白虎',
        name: '氷の威圧',
        tactic: 'リラックスサイン',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'DefenseDown',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '白虎',
        name: '専心',
        tactic: 'リラックスサイン',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '絕對零度',
        tactic: 'Ebon Knight',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '絕對零度+（3队友以上）',
        tactic: 'Ebon Knight',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [60, 75],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '絕對零度+（3队友未满）',
        tactic: 'Ebon Knight',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [60, 75],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '寒冰斬',
        tactic: 'Ebon Knight',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '殺手之鐮',
        tactic: '我が道を行く',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '一擊必殺微笑',
        tactic: '山脇様、ご乱心',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [500, 500],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '狂怒馴鹿',
        tactic: 'Holy Knight',
        hit: 4,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'AttackUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '奉獻禮物',
        tactic: 'Holy Knight',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '殺戮之刃',
        tactic: '魔王の帰還',
        hit: 4,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '觉醒的思维（首次）',
        tactic: 'Daydream Believer',
        hit: 10,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 149,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042, 10211],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '觉醒的思维',
        tactic: 'Daydream Believer',
        hit: 10,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042, 10211],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '斬の威圧',
        tactic: '山脇様、ご乱心',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '勇猛',
        tactic: '山脇様、ご乱心',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '英雄の凱歌',
        tactic: 'Holy Knight',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '疾風',
        tactic: '我が道を行く',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '梅雨',
        tactic: 'Daydream Believer',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [500, 500],
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
            },
        ],
    },
    {
        memberName: '樱庭星罗',
        name: '冰冷波動',
        tactic: '対決！！エア・ステージ',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '樱庭星罗',
        name: '北極星的光輝',
        tactic: '星の海、たゆたうフォーチュンテラー',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '樱庭星罗',
        name: '光辉震骇',
        tactic: '今日のあなたの運勢は？',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '樱庭星罗',
        name: '冰凍奇景',
        tactic: '対決！！エア・ステージ',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樱庭星罗',
        name: '決意',
        tactic: 'クリスタルの導き',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '衝擊零度',
        tactic: '山脇様の手下：マジカルにゃん',
        hit: 1,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '盧恩爆裂彈',
        tactic: 'エクスペリメンタルなキミ',
        hit: 2,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '魔莢',
        tactic: '夢浮橋辿る赤',
        hit: 2,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '貓咪大魔法',
        tactic: '山脇様の手下：マジカルにゃん',
        hit: 3,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 146,
                buffCategory: 'DefenseDown',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [2337, 11685],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.375,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '毀滅',
        tactic: 'エクスペリメンタルなキミ',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '巫師彈',
        tactic: '悠久渇望の魔術師',
        hit: 1,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 117,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '天音巫呼',
        name: '胭红魔女',
        tactic: '至高のひととき',
        hit: -1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.05, 0.02],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'DefenseDown',
                elements: ['Light'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '決心',
        tactic: 'エクスペリメンタルなキミ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '勇猛',
        tactic: '夢浮橋辿る赤',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '丰后弥生',
        name: '聖樂合唱團（首次）',
        tactic: 'Happy Legion',
        hit: 4,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '聖樂合唱團',
        tactic: 'Happy Legion',
        hit: 4,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 153,
            buffValue: [3222, 16110],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 4,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '不給糖就搗蛋',
        tactic: '夜空のShining Star',
        hit: 2,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 156,
            buffValue: [3618, 18090],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4.25,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '連發煙火',
        tactic: 'Happy Legion',
        hit: 2,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '蝴蝶餅',
        tactic: '手のひらのパーフェクション',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '小鹹餅',
        tactic: 'カニ手の使い魔',
        hit: 1,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: 'ー◯◯◯',
        tactic: 'お花見怪人クシダンゴ',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '三色团子乱射',
        tactic: 'お花見怪人クシダンゴ',
        hit: 8,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [1914, 9570],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '三色团子乱射（首次）',
        tactic: 'お花見怪人クシダンゴ',
        hit: 8,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [2312, 11561],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.1,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '義式脆餅',
        tactic: '夜空のShining Star',
        hit: 2,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'DefenseDown',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '丰后弥生',
        name: '光の強威',
        tactic: 'Happy Legion',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '丰后弥生',
        name: '野生の勘',
        tactic: '夜空のShining Star',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '神崎流忍術‧冰塵（弱点时）',
        tactic: '氷花のHexerei',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2673, 13365],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 13,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '日日夜夜',
        tactic: '少女の休息',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 7.2,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '神崎流忍術‧冰塵',
        tactic: '氷花のHexerei',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 13,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '滅殺影分身',
        tactic: 'ごちゃまぜ忍法大乱闘',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 11.2,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '一片冰心',
        tactic: 'ごちゃまぜ忍法大乱闘',
        hit: 4,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 8,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '旋風',
        tactic: '碧落の忍び',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4.8,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '神崎流忍術‧散華（弱点时）',
        tactic: '少女の休息',
        hit: 4,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [3033, 15165],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '神崎流忍術‧散華',
        tactic: '少女の休息',
        hit: 4,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '淡雪',
        tactic: '氷花のHexerei',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 192,
                buffCategory: 'AttackUp',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '心意活性',
        tactic: '微光の兆し',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '闇の強威',
        tactic: '少女の休息',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '闇の波動',
        tactic: '少女の休息',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '壮烈',
        tactic: 'ごちゃまぜ忍法大乱闘',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '氷の意志',
        tactic: '微光の兆し',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '勇猛',
        tactic: '微光の兆し',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '下一個主角就是你',
        tactic: 'Crying Tears',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '離別的早晨',
        tactic: 'Crying Tears',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '撒播',
        tactic: 'アサシン忍法大繁盛',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '增幅加農炮',
        tactic: '甘美のMuzzle',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '下一個主角就是你（破）',
        tactic: 'Crying Tears',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '廢墟‧幻想',
        tactic: 'アサシン忍法大繁盛',
        hit: -1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 240,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '廢墟‧幻想（首次）',
        tactic: 'アサシン忍法大繁盛',
        hit: -1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 240,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [30, 30],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 240,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '飽和增強',
        tactic: 'はにかむ、心かき集め',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '鑽石塵',
        tactic: '甘美のMuzzle',
        hit: 4,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Ice'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '佐月麻里',
        name: '增強',
        tactic: 'ビジネスとスマイル',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '氷の強威',
        tactic: 'Crying Tears',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '氷の波動',
        tactic: 'Crying Tears',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '凍れる陣',
        tactic: '甘美のMuzzle',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'Zone',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '突の意志',
        tactic: 'はにかむ、心かき集め',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '英勇靈魂',
        tactic: '君がいるだけで',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '閃電審判',
        tactic: 'Infernal Sanctuary',
        hit: 12,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2175, 10875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 18,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '天降聖光',
        tactic: 'Awakening Iris',
        hit: 1,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '嚴明',
        tactic: 'Awakening Iris',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '雷鳴',
        tactic: 'Infernal Sanctuary',
        hit: 4,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 10,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '英勇之劍',
        tactic: 'Ally',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '海灘狂戰士',
        tactic: '真夏のジャンダルム',
        hit: 6,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [2312.25, 11561.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.1,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '海灘狂戰士（追加回合中）',
        tactic: '真夏のジャンダルム',
        hit: 6,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [2312.25, 11561.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.1,
            },
        },
    },
    {
        memberName: '白河结奈',
        name: '紅臉烙印',
        tactic: '真夏のジャンダルム',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '引導的號令',
        tactic: 'Sign',
        hit: -1,
        sp: 8,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 224,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '闇の強威',
        tactic: '真夏のジャンダルム',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '闇の波動',
        tactic: '真夏のジャンダルム',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '烈風',
        tactic: '君がいるだけで',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '雷の強威',
        tactic: 'Infernal Sanctuary',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '英雄の凱歌',
        tactic: 'Awakening Iris',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '決心',
        tactic: 'Sign',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '輝神',
        tactic: '掩蔽された暇',
        hit: 4,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '輝神（追加回合中）',
        tactic: '掩蔽された暇',
        hit: 4,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '朧',
        tactic: '掩蔽された暇',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '羅剎',
        tactic: '真宵晴るれば、一閃心静',
        hit: 1,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '烈',
        tactic: '真宵晴るれば、一閃心静',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '斷',
        tactic: '禅定、憂い無し',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '月城最中',
        name: '空',
        tactic: '黄昏、久遠の夢',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '高揚',
        tactic: '真宵晴るれば、一閃心静',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 10,
                buffValue: [5, 5],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '光の強威',
        tactic: '掩蔽された暇',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '光の意志',
        tactic: '黄昏、久遠の夢',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '桐生美也',
        name: '變天之相',
        tactic: '豊楽ノ神秘',
        hit: 1,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '桐生美也',
        name: '神妙',
        tactic: '星林遣らずの雨',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '桐生美也',
        name: '御稻荷神話',
        tactic: '豊楽ノ神秘',
        hit: 3,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 143,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 143,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 143,
            buffValue: [2025, 10125],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.125,
            },
        },
    },
    {
        memberName: '桐生美也',
        name: '光芒一閃',
        tactic: '星林遣らずの雨',
        hit: 3,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Light'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1602, 8010],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.75,
            },
        },
    },
    {
        memberName: '桐生美也',
        name: '薙矢',
        tactic: '神祠見舞う儚さかな',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 126,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '桐生美也',
        name: '咒怨之箭',
        tactic: '幽幻閑寂いとらうたし',
        hit: 1,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 120,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '菅原千惠',
        name: '純真狂野+',
        tactic: '終末ロリータ白書',
        hit: 3,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 141,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '菅原千惠',
        name: '公主殿下的寵愛',
        tactic: '亡国の純心',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 0,
                wis: 0,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 1,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '菅原千惠',
        name: '純真狂野',
        tactic: '終末ロリータ白書',
        hit: 3,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 141,
            buffValue: [1446, 7230],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.4,
            },
        },
    },
    {
        memberName: '菅原千惠',
        name: '削弱光線',
        tactic: '終末ロリータ白書',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '菅原千惠',
        name: '燦爛榮耀',
        tactic: '気まぐれのアンニュイ',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [200, 200],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '菅原千惠',
        name: '光の躍動',
        tactic: 'センパイの品格',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'CriticalDamageUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '菅原千惠',
        name: '鋭気',
        tactic: '亡国の純心',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '夢幻願望',
        tactic: '希求と渇仰',
        hit: 3,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '劍擊女僕',
        tactic: '萌えよ天才剣士',
        hit: 5,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [1914, 9570],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '劍擊女僕（蓄力中）',
        tactic: '萌えよ天才剣士',
        hit: 5,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [2312.25, 11561.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.1,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '三日月宗近',
        tactic: '朧月夜のバレット',
        hit: 4,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '全力槍擊',
        tactic: '静寂閑雅のレスト',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [738, 3690],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.75,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '朧月',
        tactic: '仄かに揺らぐファイア',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '三日月宗近+',
        tactic: '朧月夜のバレット',
        hit: 4,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 146,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '某某衝擊',
        tactic: '希求と渇仰',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '夜雨',
        tactic: '朧月夜のバレット',
        hit: 1,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 131,
                buffCategory: 'DefenseDown',
                elements: ['Light'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 131,
                buffCategory: 'DefenseDown',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 131,
            buffValue: [1017, 5085],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.125,
            },
        },
    },
    {
        memberName: '小笠原绯雨',
        name: '女僕的素養',
        tactic: '萌えよ天才剣士',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '闇の強威',
        tactic: '萌えよ天才剣士',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '勇猛',
        tactic: '朧月夜のバレット',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '専心',
        tactic: '静寂閑雅のレスト',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '最狠的款待（弱点时）',
        tactic: '若女将の日々',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2175, 10875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 11.2,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '最狠的款待',
        tactic: '若女将の日々',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 11.2,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '收成祭',
        tactic: '此に期するは豊穣の御霊',
        hit: 6,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 21,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '地獄變',
        tactic: '清流に坐す',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 7.2,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '斜陽',
        tactic: 'きおい佳人',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 5,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '全力打理',
        tactic: '若女将の日々',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 6,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '細雪',
        tactic: '夜警の空',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '夢十夜',
        tactic: '此に期するは豊穣の御霊',
        hit: 4,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 132,
                buffCategory: 'AttackUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 9,
            },
        },
    },
    {
        memberName: '藏里见',
        name: '闇の強威',
        tactic: '若女将の日々',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '斬の意志',
        tactic: '夜警の空',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '烈風',
        tactic: '夜警の空',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '栄光の陣',
        tactic: '此に期するは豊穣の御霊',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'Zone',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '勇猛',
        tactic: '清流に坐す',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '冰凍圓舞曲',
        tactic: 'Holiday Ring a Bell',
        hit: 12,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '石塔的手筋',
        tactic: '無上の終局',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1602, 8010],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.75,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '石塔的手筋（OD中）',
        tactic: '無上の終局',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '究極的一手',
        tactic: '無上の終局',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '石塔的手筋+',
        tactic: '無上の終局',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1602, 8010],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.75,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '石塔的手筋+（OD中）',
        tactic: '無上の終局',
        hit: 1,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 17],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '冰凍圓舞曲（OD中）',
        tactic: 'Holiday Ring a Bell',
        hit: 12,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2175, 10875],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '北風的昴星',
        tactic: 'Holiday Ring a Bell',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 192,
                buffCategory: 'AttackUp',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '冷卻',
        tactic: '今昔の感、想いは往きて',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '扳殺',
        tactic: '最年少名人',
        hit: 3,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 120,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '二阶堂三乡',
        name: '氷の強威',
        tactic: 'Holiday Ring a Bell',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '氷の波動',
        tactic: 'Holiday Ring a Bell',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '専心',
        tactic: '無上の終局',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '戰鬥熱情',
        tactic: 'ピュア・エモーション',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '石井色叶',
        name: '極彩射擊',
        tactic: '多彩なるインスピレーション',
        hit: 1,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '石井色叶',
        name: '無色子彈',
        tactic: '撃砕の無彩色',
        hit: 5,
        sp: 12,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 50],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Zone',
                elements: ['Dark'],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '石井色叶',
        name: '飽和增強',
        tactic: '撃砕の無彩色',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '增強',
        tactic: 'お気楽カラフル',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '闇夜の陣',
        tactic: '撃砕の無彩色',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'Zone',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: 'R.I.P.',
        tactic: 'Silent Reverberations',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '命吹雪',
        name: '以破滅道晚安',
        tactic: '終末なにする？',
        hit: 8,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 150,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '命吹雪',
        name: '以破滅道晚安（OD中）',
        tactic: '終末なにする？',
        hit: 8,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 150,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2850, 14250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.75,
            },
        },
    },
    {
        memberName: '命吹雪',
        name: '可別先掛點了啊',
        tactic: '終末なにする？',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'DefenseDown',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '命吹雪',
        name: '狂怒嘶吼',
        tactic: 'Metal Crazy',
        hit: 3,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 120,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '命吹雪',
        name: '弱化',
        tactic: '終末なにする？',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '斬の躍動',
        tactic: 'Silent Reverberations',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [8, 8],
                buffCategory: 'CriticalDamageUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '疾風',
        tactic: 'Silent Reverberations',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '勇気',
        tactic: 'Metal Crazy',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '室伏理沙',
        name: '淒美覺醒',
        tactic: 'あまいろの花',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '室伏理沙',
        name: '暖心轟炸機',
        tactic: '早くおうちに帰りましょ',
        hit: 3,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '室伏理沙',
        name: '重生彈',
        tactic: '痛いの痛いの飛んでけ〜♪',
        hit: 3,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '哀傷的雪花蓮',
        tactic: 'Holiday Star Night',
        hit: 2,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [2337, 11685],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.375,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '雪崩',
        tactic: 'Holiday Star Night',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '切削球',
        tactic: 'マーベルアプローチ',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '發球得分',
        tactic: 'ネガティブエース',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '漆黑迷幻發球',
        tactic: 'テニスコートの悪魔',
        hit: 8,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '伊达朱里',
        name: '被害妄想',
        tactic: 'テニスコートの悪魔',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 192,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '氷の強威',
        tactic: 'Holiday Star Night',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '氷の波動',
        tactic: 'Holiday Star Night',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '決心',
        tactic: 'テニスコートの悪魔',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '闇の意志',
        tactic: 'マーベルアプローチ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '疾風',
        tactic: 'マーベルアプローチ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '疾風',
        tactic: 'ネガティブエース',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '出刃菜刀登場',
        tactic: '奥深い味わいを',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '瑞原蓝娜',
        name: '大海不良分子',
        tactic: 'ロックアップオルカ',
        hit: 6,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '瑞原蓝娜',
        name: '浩瀚大海',
        tactic: 'ロックアップオルカ',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '瑞原蓝娜',
        name: '深層吐息',
        tactic: 'アビスからの誘い',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 120,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.25,
            },
        },
    },
    {
        memberName: '瑞原蓝娜',
        name: '皺鰓鯊硬刃',
        tactic: '深き海の学び手',
        hit: 1,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '瑞原蓝娜',
        name: '氷の躍動',
        tactic: '奥深い味わいを',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'CriticalDamageUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '決心',
        tactic: '奥深い味わいを',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '弱化',
        tactic: 'ロックアップオルカ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '激昂壯志',
        tactic: '果てなき慈愛の守護者',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '大岛一千子',
        name: '我不能輸',
        tactic: '同床異夢明けて',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '大岛一千子',
        name: '送上誓言的證明吧！',
        tactic: 'Sweet Phantasy',
        hit: 5,
        sp: 16,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 152,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [3033, 15165],
            iZon: {
                str: 0,
                wis: 0,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 1,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.875,
            },
        },
    },
    {
        memberName: '大岛一千子',
        name: '慈愛的波動',
        tactic: '果てなき慈愛の守護者',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 232,
                buffCategory: 'AttackUp',
                elements: ['Fire'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '鋭気',
        tactic: 'Sweet Phantasy',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '火の波動',
        tactic: 'Sweet Phantasy',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '純愛野心+',
        tactic: 'Brand New Mind',
        hit: 8,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 21,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '誘掖之途',
        tactic: '心緒、昂る温泉郷',
        hit: 4,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 9,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '初戀',
        tactic: '戸惑いの波際',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '純愛野心',
        tactic: 'Brand New Mind',
        hit: 8,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 14,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '革命',
        tactic: 'Brand New Mind',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 8,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '櫻華',
        tactic: '戦線キャットウォーク',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '碎華',
        tactic: '二以奈の気品',
        hit: 4,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '仁慈曼舞金風中',
        tactic: '心緒、昂る温泉郷',
        hit: 8,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '仁慈曼舞金風中（破）',
        tactic: '心緒、昂る温泉郷',
        hit: 8,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '大岛二以奈',
        name: '光の采配',
        tactic: '心緒、昂る温泉郷',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '火の威圧',
        tactic: '戸惑いの波際',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'DefenseDown',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '烈風',
        tactic: '戸惑いの波際',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛三野里',
        name: '旋風硬刃',
        tactic: '韋駄天配達人',
        hit: 1,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 138,
            buffValue: [1239, 6195],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.2,
            },
        },
    },
    {
        memberName: '大岛三野里',
        name: '威力衝撞',
        tactic: 'Realize Your Mind',
        hit: 2,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '大岛三野里',
        name: '衝刺硬刃',
        tactic: '三野里の疾走',
        hit: 2,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1,
            },
        },
    },
    {
        memberName: '大岛三野里',
        name: '飄然亢奮',
        tactic: 'Realize Your Mind',
        hit: 3,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [1914, 9570],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.8,
            },
        },
    },
    {
        memberName: '大岛三野里',
        name: '火の強威',
        tactic: 'Realize Your Mind',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '大岛三野里',
        name: '勇猛',
        tactic: '韋駄天配達人',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '晚安',
        tactic: 'ぐうたらパジャマナイト',
        hit: 1,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 0,
                wis: 1,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '大岛四叶草',
        name: '糖果爆裂彈',
        tactic: 'Lollipop Break',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '大岛四叶草',
        name: '爆裂泡泡',
        tactic: 'ぐうたらパジャマナイト',
        hit: -1,
        sp: 13,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 264,
                buffCategory: 'AttackUp',
                elements: ['Light'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Light'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [300, 300],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '夢寐以求的幻想覺醒',
        tactic: '破られたアンニュイ',
        hit: -1,
        sp: 12,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 256,
                buffCategory: 'AttackUp',
                elements: ['Fire'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Fire'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '決戰前夕',
        tactic: '破られたアンニュイ',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '增強',
        tactic: '四ツ葉の倦怠',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '光の応援',
        tactic: 'ぐうたらパジャマナイト',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '火の意志',
        tactic: 'Lollipop Break',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '鋒芒盡現',
        tactic: '湯上がり夢現郷',
        hit: 4,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '大岛五十铃',
        name: '麻煩製造機',
        tactic: '五十鈴の秘密',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '大岛五十铃',
        name: '發燙的肉體',
        tactic: '湯上がり夢現郷',
        hit: 1,
        sp: 10,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.05, 0.02],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'DefenseDown',
                elements: ['dp'],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [80, 140],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '勝利者留心的事',
        tactic: 'ピースフルフレーバー',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'DefenseDown',
                elements: ['Fire'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '大岛五十铃',
        name: '魔炎閃獄門',
        tactic: '勝利への鍵',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '大岛五十铃',
        name: '怒裂昂',
        tactic: '勝利への鍵',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'DefenseDown',
                elements: ['Fire'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '大岛五十铃',
        name: '弱化',
        tactic: '勝利への鍵',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '勇猛',
        tactic: 'ピースフルフレーバー',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '大岛六宇亚',
        name: '生存之業',
        tactic: 'グルーヴィーなロール',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '大岛六宇亚',
        name: '迷幻痛楚',
        tactic: 'ピンチで最高',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '大岛六宇亚',
        name: '快感短跑！',
        tactic: 'ピンチで最高',
        hit: -1,
        sp: 11,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛六宇亚',
        name: '火の躍動',
        tactic: 'グルーヴィーなロール',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'CriticalDamageUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '柳美音',
        name: '排氣聲浪',
        tactic: '夜風のChill Time',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '柳美音',
        name: '華麗的掃除',
        tactic: '滅私奉公のトラスト',
        hit: 2,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '柳美音',
        name: '優雅殼層',
        tactic: 'Wild Rose',
        hit: -1,
        sp: 11,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '主人的職責',
        tactic: '木漏れ日の庭',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.25,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '虛空風暴',
        tactic: 'エボリューションな感受性',
        hit: 3,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '虛空風暴（蓄力时）',
        tactic: 'エボリューションな感受性',
        hit: 3,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '暗影洞察力',
        tactic: 'お嬢様は端麗',
        hit: 3,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '巨型加農炮',
        tactic: 'リトルハイセンス',
        hit: 3,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [294, 1470],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 0.8,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '精巧彈',
        tactic: 'エボリューションな感受性',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '丸山奏多',
        name: '烈風',
        tactic: '木漏れ日の庭',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '壮烈',
        tactic: 'エボリューションな感受性',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '闇の威圧',
        tactic: 'お嬢様は端麗',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'DefenseDown',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '疾風',
        tactic: 'お嬢様は端麗',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '決意',
        tactic: 'リトルハイセンス',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '华村诗纪',
        name: '別有居心的詠嘆調',
        tactic: '君のUnisono',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '华村诗纪',
        name: '獻給鳥兒的交響曲',
        tactic: '君のUnisono',
        hit: 4,
        sp: 11,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [65, 80],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 135,
                buffCategory: 'AttackUp',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '华村诗纪',
        name: '聖域的清唱劇',
        tactic: '木漏れ日のソナタ',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
    },
    {
        memberName: '华村诗纪',
        name: '增強',
        tactic: 'アバンチュールコンダクター',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '必滅！徒然飛踢+',
        tactic: '疾風迅速滅亡の狼煙',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 17],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '松岗绮罗路',
        name: '必滅！徒然飛踢',
        tactic: '疾風迅速滅亡の狼煙',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '松岗绮罗路',
        name: '疾黑破彈',
        tactic: '疾風迅速滅亡の狼煙',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '松岗绮罗路',
        name: '匯聚重擊',
        tactic: '天才かませ犬',
        hit: 3,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '松岗绮罗路',
        name: '後方準備',
        tactic: '秘めたる努力',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [500, 500],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '怒涛',
        tactic: '疾風迅速滅亡の狼煙',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'CriticalDamageUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '打の躍動',
        tactic: '秘めたる努力',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [8, 8],
                buffCategory: 'CriticalDamageUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '優雅嚴明',
        tactic: '薫衣香る夢見鳥',
        hit: 5,
        sp: 18,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 159,
            buffValue: [3066, 15330],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.6,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '夜晚的強風',
        tactic: '剣の冷徹',
        hit: 6,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2178, 10890],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 3.25,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '夜晚的強風（蓄力时）',
        tactic: '剣の冷徹',
        hit: 6,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.625,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '瞬刃',
        tactic: '彼岸の人斬り',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '優雅嚴明（蓄力时）',
        tactic: '薫衣香る夢見鳥',
        hit: 5,
        sp: 18,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 30],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 159,
            buffValue: [3066, 15330],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.6,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '冀望',
        tactic: '薫衣香る夢見鳥',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '冷卻',
        tactic: '剣の冷徹',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '殘心',
        tactic: '天誅斬影',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '夏目祈',
        name: '怒涛',
        tactic: '薫衣香る夢見鳥',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'CriticalDamageUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '闇の強威',
        tactic: '剣の冷徹',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Dark'],
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '決心',
        tactic: '彼岸の人斬り',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '勇気',
        tactic: '天誅斬影',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '黑泽真希',
        name: '猛力橫掃',
        tactic: 'ハレの日の仏恥義理',
        hit: 1,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 6.4,
            },
        },
    },
    {
        memberName: '黑泽真希',
        name: '一發入魂',
        tactic: 'Highway of Angels',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [450, 2250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4,
            },
        },
    },
    {
        memberName: '黑泽真希',
        name: '霸道妄執我突邁進',
        tactic: 'ハレの日の仏恥義理',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 149,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2178, 10890],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 13,
            },
        },
    },
    {
        memberName: '黑泽真希',
        name: '霸道妄執我突邁進（蓄力时）',
        tactic: 'ハレの日の仏恥義理',
        hit: 6,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 149,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2673, 13365],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 21.75,
            },
        },
    },
    {
        memberName: '黑泽真希',
        name: '喧嘩上等！！',
        tactic: '唯我独尊',
        hit: 1,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 132,
                buffCategory: 'BuffCharge',
                elements: [],
                maxCount: 1,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [879, 4395],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.8,
            },
        },
    },
    {
        memberName: '黑泽真希',
        name: '狂乱の型',
        tactic: 'ハレの日の仏恥義理',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [300, 300],
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
            },
        ],
    },
    {
        memberName: '黑泽真希',
        name: '打の意志',
        tactic: '唯我独尊',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '雷霆風暴',
        tactic: '摩天楼のダークヒーロー',
        hit: 4,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [1790.25, 8951.25],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.7,
            },
        },
    },
    {
        memberName: '卡罗尔·利帕',
        name: '制裁者',
        tactic: '心の風景',
        hit: 4,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 134,
            buffValue: [1233, 6165],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 2.375,
            },
        },
    },
    {
        memberName: '卡罗尔·利帕',
        name: '爆破斬',
        tactic: '星の継承者',
        hit: 3,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [294, 1470],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 0.8,
            },
        },
    },
    {
        memberName: '卡罗尔·利帕',
        name: '閃電怒吼',
        tactic: '摩天楼のダークヒーロー',
        hit: 4,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 129,
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [918, 4590],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '卡罗尔·利帕',
        name: '英雄の凱歌',
        tactic: '摩天楼のダークヒーロー',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '烈風',
        tactic: '心の風景',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '勇気',
        tactic: '星の継承者',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '春雷',
        tactic: 'いざなうつゆくさ',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '李映夏',
        name: '霓裳羽衣舞‧霹靂',
        tactic: 'いざなうつゆくさ',
        hit: -1,
        sp: 11,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 45],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'CriticalDamageUp',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '第七計 無中生有',
        tactic: '我、勇ならざるは将なきに同じ',
        hit: -1,
        sp: 11,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 248,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [500, 500],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 10,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '臥龍天命',
        tactic: '我、勇ならざるは将なきに同じ',
        hit: -1,
        sp: 7,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [75, 90],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 216,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '臥龍演舞斬',
        tactic: '武を成すは天に在り',
        hit: 4,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 123,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [582, 2910],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.5,
            },
        },
    },
    {
        memberName: '李映夏',
        name: '增強',
        tactic: '臥龍の代弁者',
        hit: -1,
        sp: 6,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 208,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '雷の采配',
        tactic: 'いざなうつゆくさ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '真相洞悉者',
        tactic: '碧いカーヴァンクル',
        hit: 3,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 149,
            buffValue: [2042.25, 10211.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.9,
            },
        },
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '基礎子彈',
        tactic: '不可思議の探求者',
        hit: 1,
        sp: 4,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 117,
            buffValue: [342, 1710],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '堅忍意志',
        tactic: '碧いカーヴァンクル',
        hit: -1,
        sp: 8,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [200, 200],
                iZon: {
                    str: 0,
                    wis: 0,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 0,
                buffCategory: 'Funnel',
                elements: [],
                funnelValue: 40,
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '壮烈',
        tactic: '碧いカーヴァンクル',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [20, 20],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '烈風',
        tactic: 'ベイカー街の軌跡',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '疾風',
        tactic: '不可思議の探求者',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '生存食糧',
        tactic: '華麗なる日常',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 0,
                wis: 0,
                dex: 0,
                spr: 0,
                luk: 0,
                con: 1,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '瘋狂亂舞的迦梨',
        tactic: '凛々しきドゥルガー',
        hit: 10,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 140,
            buffValue: [1737, 8685],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.875,
            },
        },
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '原理為韻律學',
        tactic: '未知数なヴェーダーンガ',
        hit: 3,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 131,
            buffValue: [1017, 5085],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.125,
            },
        },
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '整體減法',
        tactic: '華麗なるアプサラス',
        hit: 3,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.2,
            },
        },
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '雷の威圧',
        tactic: '未知数なヴェーダーンガ',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [12, 12],
                buffCategory: 'DefenseDown',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '救贖的送葬曲',
        tactic: '刹那の邂逅',
        hit: 5,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 144,
            buffValue: [1671, 8355],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.6,
            },
        },
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '零之斷罪',
        tactic: 'ブラッド・レリーフ',
        hit: 5,
        sp: 14,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 146,
            buffValue: [2337, 11685],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 3.375,
            },
        },
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '獻祭',
        tactic: 'ブラッド・レリーフ',
        hit: 2,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 125,
            buffValue: [657, 3285],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.625,
            },
        },
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '雷の強威',
        tactic: '刹那の邂逅',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '打の威圧',
        tactic: '罪と栄光',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'DefenseDown',
                elements: [],
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '驚慌失措的子彈',
        tactic: '逆境に咲く華',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.25,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '貓型坦克（30sp）',
        tactic: '雪華ノスタルジア',
        hit: 3,
        sp: -1,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [3618, 18090],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4.25,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '災厄',
        tactic: '北風の使者',
        hit: 3,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '純潔之吻（赋予弱点）',
        tactic: 'とこしえの想い',
        sp: 15,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 141,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '純潔之吻',
        tactic: 'とこしえの想い',
        hit: 3,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 2,
                    con: 0,
                },
                threshold: 141,
                buffCategory: 'Fragile',
                elements: [],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 141,
            buffValue: [1446, 7230],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.4,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '依存',
        tactic: 'とこしえの想い',
        hit: 3,
        sp: 5,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 120,
                buffCategory: 'DefenseDown',
                elements: ['Thunder'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 120,
            buffValue: [375, 1875],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '為你炸開的手榴彈',
        tactic: '逆境に咲く華',
        hit: 3,
        sp: 13,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [20, 30],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 140,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 140,
                buffCategory: 'DefenseDown',
                elements: ['Dark'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 140,
            buffValue: [1340.25, 6701.25],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.3,
            },
        },
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '雷の制圧',
        tactic: 'とこしえの想い',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'DefenseDown',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '雷の波動',
        tactic: 'とこしえの想い',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Thunder'],
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '勇気',
        tactic: '北風の使者',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [15, 15],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '幸運斬',
        tactic: 'Earth Angel',
        hit: 2,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '立华奏',
        name: '天使光線',
        tactic: 'Earth Angel',
        hit: 8,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 13],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [40, 55.00000000000001],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 147,
                buffCategory: 'AttackUp',
                elements: ['Light'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 147,
            buffValue: [2502, 12510],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 3.5,
            },
        },
    },
    {
        memberName: '立华奏',
        name: '洞察力',
        tactic: 'Earth Angel',
        hit: -1,
        sp: 5,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0.03, 0.02],
                buffValue: [35, 50],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'MindEye',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '光の強威',
        tactic: 'Earth Angel',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '光の波動',
        tactic: 'Earth Angel',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Light'],
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '大災難',
        tactic: 'Rain Fire',
        hit: 10,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 13],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 150,
            buffValue: [2850, 14250],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 15,
            },
        },
    },
    {
        memberName: '仲村百合',
        name: '爆裂火焰',
        tactic: 'Rain Fire',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 10,
            },
        },
    },
    {
        memberName: '仲村百合',
        name: '突襲',
        tactic: 'Rain Fire',
        hit: 2,
        sp: 6,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 123,
            buffValue: [474, 2370],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 4.8,
            },
        },
    },
    {
        memberName: '仲村百合',
        name: '火の強威',
        tactic: 'Rain Fire',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '火の波動',
        tactic: 'Rain Fire',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Fire'],
            },
        ],
    },
    {
        memberName: '入江美雪',
        name: '冷冽打擊',
        tactic: 'Faraway Eden',
        hit: 3,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1050, 5250],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2,
            },
        },
    },
    {
        memberName: '入江美雪',
        name: '正拍',
        tactic: 'Pure Cosmos',
        hit: 3,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '入江美雪',
        name: '陽動作戦',
        tactic: 'Pure Cosmos',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [30, 30],
                buffCategory: 'AttackUp',
                elements: [],
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '冰冻瞬间',
        tactic: '天翔ける剣',
        hit: 2,
        sp: 8,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 129,
            buffValue: [726, 3630],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.3,
                dr: 1.6,
            },
        },
    },
    {
        memberName: '立华奏',
        name: '垂直力',
        tactic: '天翔ける剣',
        hit: 6,
        sp: 15,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 155,
            buffValue: [3417, 17085],
            iZon: {
                str: 2,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1.5,
                dr: 4.215,
            },
        },
    },
    {
        memberName: '立华奏',
        name: '氷の波動',
        tactic: '天翔ける剣',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [10, 10],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '冰霜棘刺',
        tactic: 'ありふれた非日常',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.3,
                hp: 1,
                dr: 2.5,
            },
        },
    },
    {
        memberName: '仲村百合',
        name: '冷冽子弹',
        tactic: 'ありふれた非日常',
        hit: 1,
        sp: 10,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 135,
            buffValue: [1350, 6750],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 10,
            },
        },
    },
    {
        memberName: '仲村百合',
        name: '真实冰锥',
        tactic: 'ありふれた非日常',
        hit: 4,
        sp: 16,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 152,
            buffValue: [3184, 15468],
            iZon: {
                str: 1,
                wis: 0,
                dex: 2,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1.5,
                hp: 1,
                dr: 3.875,
            },
        },
    },
    {
        memberName: '仲村百合',
        tactic: 'ありふれた非日常',
        name: '氷の強威',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [25, 25],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '渕田久子',
        name: '大肆破坏',
        tactic: 'Finally found our silver lining',
        hit: 1,
        sp: 7,
        isAtkSkill: true,
        isBuffSkill: false,
        isPassiveSkill: false,
        level: [1, 10],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 126,
            buffValue: [591, 2955],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 1.4,
            },
        },
    },
    {
        memberName: '渕田久子',
        name: '冷冽碎击',
        tactic: 'Finally found our silver lining',
        hit: 2,
        sp: 9,
        isAtkSkill: true,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 10],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [45, 60],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 132,
                buffCategory: 'DefenseDown',
                elements: ['Ice'],
                maxCount: 2,
            },
        ],
        atkPart: {
            growth: [0.05, 0.02],
            threshold: 132,
            buffValue: [1122, 5610],
            iZon: {
                str: 1,
                wis: 0,
                dex: 1,
                spr: 0,
                luk: 0,
                con: 0,
            },
            multipliers: {
                dp: 1,
                hp: 1,
                dr: 2.25,
            },
        },
    },
    {
        memberName: '渕田久子',
        name: '氷の采配',
        tactic: 'Finally found our silver lining',
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: true,
        level: [1, 1],
        buffParts: [
            {
                maxCount: 1,
                buffValue: [18, 18],
                buffCategory: 'AttackUp',
                elements: ['Ice'],
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白虎',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樱庭星罗',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丰后弥生',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '桐生美也',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '菅原千惠',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '室伏理沙',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛三野里',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛六宇亚',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柳美音',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '华村诗纪',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '黑泽真希',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '入江美雪',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '渕田久子',
        name: '进攻上升（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [50, 65],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'AttackUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白虎',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樱庭星罗',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丰后弥生',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '桐生美也',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '菅原千惠',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '室伏理沙',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛三野里',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛六宇亚',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柳美音',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '华村诗纪',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '黑泽真希',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '入江美雪',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '渕田久子',
        name: '软化（宝玉）',
        hit: -1,
        sp: 9,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [30, 45],
                iZon: {
                    str: 0,
                    wis: 2,
                    dex: 0,
                    spr: 0,
                    luk: 1,
                    con: 0,
                },
                threshold: 200,
                buffCategory: 'DefenseDown',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '茅森月歌',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '和泉由希',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '逢川惠',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '东城司',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '朝仓可怜',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '国见玉',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '苍井绘梨花',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑莓',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '水濑李',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樋口圣华',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柊木梢',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白虎',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '山胁·波昂·伊瓦尔',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '樱庭星罗',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '天音巫呼',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丰后弥生',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '神崎·阿黛尔海德',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '佐月麻里',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '白河结奈',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '月城最中',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '桐生美也',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '菅原千惠',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '小笠原绯雨',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '藏里见',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '二阶堂三乡',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '石井色叶',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '命吹雪',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '室伏理沙',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '伊达朱里',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '瑞原蓝娜',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛一千子',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛二以奈',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛三野里',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛四叶草',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛五十铃',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '大岛六宇亚',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '柳美音',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '丸山奏多',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '华村诗纪',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '松岗绮罗路',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏目祈',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '黑泽真希',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '卡罗尔·利帕',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '李映夏',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '艾琳·雷德梅因',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '芙里提卡·巴拉克里希南',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '玛丽亚·黛·安杰利斯',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '夏洛特·斯可波夫斯加',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '立华奏',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '仲村百合',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '入江美雪',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
    {
        memberName: '渕田久子',
        name: '专注力（宝玉）',
        hit: -1,
        sp: 4,
        isAtkSkill: false,
        isBuffSkill: true,
        isPassiveSkill: false,
        level: [1, 1],
        buffParts: [
            {
                growth: [0, 0],
                buffValue: [10, 20],
                iZon: {
                    str: 0,
                    wis: 1,
                    dex: 0,
                    spr: 0,
                    luk: 0,
                    con: 0,
                },
                threshold: 450,
                buffCategory: 'CriticalDamageUp',
                elements: [],
                maxCount: 2,
            },
        ],
    },
].sort((a, b) => {
    if (a.memberName !== b.memberName) {
        return (
            memberData.findIndex(member => member.name === a.memberName) -
            memberData.findIndex(member => member.name === b.memberName)
        );
    } else if (a.isPassiveSkill ^ b.isPassiveSkill) {
        return a.isPassiveSkill ? 1 : -1;
    } else if (!a.isPassiveSkill && !b.isPassiveSkill) {
        if ((a.isAtkSkill && !a.isBuffSkill) ^ (b.isAtkSkill && !b.isBuffSkill)) {
            return a.isAtkSkill && !a.isBuffSkill ? -1 : 1;
        } else if (a.isBuffSkill ^ b.isBuffSkill) {
            return a.isAtkSkill ? 1 : -1;
        }
    }

    return -1;
});
