// 所有人员
export const memberData = [
    // 31A
    { name: '茅森月歌', originalName: '茅森 月歌 — Ruka Kayamori — ' },
    { name: '和泉由希', originalName: '和泉 ユキ — Yuki Izumi — ユッキー Yukkii' },
    { name: '逢川惠', originalName: '逢川 めぐみ — Megumi Aikawa — めぐみん Megumin' },
    { name: '东城司', originalName: '東城 つかさ — Tsukasa Tojo — つかさっち Tsukasacchi' },
    { name: '朝仓可怜', originalName: '朝倉 可憐 — Karen Asakura — かれりん Karerin' },
    { name: '国见玉', originalName: '國見 タマ — Tama Kunimi — おタマさん Otamasan' },
    // 31B
    { name: '苍井绘梨花', originalName: '蒼井 えりか — Erika Aoi — ' },
    { name: '水濑莓', originalName: '水瀬 いちご — Ichigo Minase — ' },
    { name: '水濑李', originalName: '水瀬 すもも — Sumomo Minase — ' },
    { name: '樋口圣华', originalName: '樋口 聖華 — Seika Higuchi — ひぐみん Higumin' },
    { name: '柊木梢', originalName: '柊木 梢 — Kozue Hiiragi — こじゅ Koju' },
    { name: '白虎', originalName: 'ビャッコ — Byakko — ' },
    // 31C
    { name: '山胁·波昂·伊瓦尔', originalName: '山脇・ボン・イヴァール — Ivar Bon Yamawaki — ワッキー Wakkii' },
    { name: '樱庭星罗', originalName: '桜庭 星羅 — Seira Sakuraba — さくちゃん SakuChan' },
    { name: '天音巫呼', originalName: '天音 巫呼 — Miko Tenne — 天然ちゃん TennenChan' },
    { name: '丰后弥生', originalName: '豊後 弥生 — Yayoi Bungo — ぶんちゃん BunChan' },
    { name: '神崎·阿黛尔海德', originalName: '神崎 アーデルハイド — Adelheid Kanzaki — アーさん AaSan' },
    { name: '佐月麻里', originalName: '佐月 マリ — Mari Satsuki — マリー Marii' },
    // 30G
    { name: '白河结奈', originalName: '白河 ユイナ — Yuina Shirakawa — ユイナ先輩 YuinaSenpai' },
    { name: '月城最中', originalName: '月城 最中 — Monaka Tsukishiro — もなにゃん Monanyan' },
    { name: '桐生美也', originalName: '桐生 美也 — Miya Kiryu — みゃーさん MyaaSan' },
    { name: '菅原千惠', originalName: '菅原 千恵 — Chie Sugawara — 菅やん Sugayan' },
    { name: '小笠原绯雨', originalName: '小笠原 緋雨 — Hisame Ogasahara — 緋雨っち Hisamecchi' },
    { name: '藏里见', originalName: '蔵 里見 — Satomi Kura — 蔵っち Kuracchi' },
    // 31D
    { name: '二阶堂三乡', originalName: '二階堂 三郷 — Misato Nikaido — みさりん Misarin' },
    { name: '石井色叶', originalName: '石井 色葉 — Iroha Ishii — いろっち Irocchi' },
    { name: '命吹雪', originalName: '命 吹雪 — Fubuki Mikoto — ふぶきん Fubukin' },
    { name: '室伏理沙', originalName: '室伏 理沙 — Risa Murofushi — りさママ RisaMama' },
    { name: '伊达朱里', originalName: '伊達 朱里 — Akari Date — あかりん Akarin' },
    { name: '瑞原蓝娜', originalName: '瑞原 あいな — Aina Mizuhara — あいちん Aichin' },
    // 31E
    { name: '大岛一千子', originalName: '大島 一千子 — Ichiko Ohshima — いっちー Icchii' },
    { name: '大岛二以奈', originalName: '大島 二以奈 — Niina Ohshima — ニーナ Niina' },
    { name: '大岛三野里', originalName: '大島 三野里 — Minori Ohshima — みのりん Minorin' },
    { name: '大岛四叶草', originalName: '大島 四ツ葉 — Yotsuha Ohshima — よっつん Yottsun' },
    { name: '大岛五十铃', originalName: '大島 五十鈴 — Isuzu Ohshima — 五十鈴っち Isuzucchi' },
    { name: '大岛六宇亚', originalName: '大島 六宇亜 — Muua Ohshima — ムーア Muua' },
    // 31F
    { name: '柳美音', originalName: '柳 美音 — Mion Yanagi — やなぎん Yanagin' },
    { name: '丸山奏多', originalName: '丸山 奏多 — Kanata Maruyama — まるちゃん MaruChan' },
    { name: '华村诗纪', originalName: '華村 詩紀 — Shiki Hanamura — シッキー Shikkii' },
    { name: '松岗绮罗路', originalName: '松岡 チロル — Chiroru Matsuoka — マツチロ Matsuchiro' },
    { name: '夏目祈', originalName: '夏目 祈 — Inori Natsume — いのりん Inorin' },
    { name: '黑泽真希', originalName: '黒沢 真希 — Maki Kurosawa — まっきー Makkii' },
    // 31X
    { name: '卡罗尔·利帕', originalName: 'キャロル・リーパー — Carole Reaper — キャロル Kyaroru' },
    { name: '李映夏', originalName: '李 映夏 — Yingxia Li — ユンユン Yunyun' },
    { name: '艾琳·雷德梅因', originalName: 'アイリーン・レドメイン — Irene Redmayne — アイリン Airin' },
    {
        name: '芙里提卡·巴拉克里希南',
        originalName: 'ヴリティカ・バラクリシュナン — Vritika Balakrishnan — ヴリちゃん VuriChan',
    },
    { name: '玛丽亚·黛·安杰利斯', originalName: 'マリア・デ・アンジェリス — Maria de Angelis — マリリン Maririn' },
    {
        name: '夏洛特·斯可波夫斯加',
        originalName: 'シャルロッタ・スコポフスカヤ — Charlotta Skopovskaya — シャロ Sharo',
    },
    // Angel Beats
    { name: '立华奏', originalName: '立華 かなで — Kanade Tachibana — ' },
    { name: '仲村百合', originalName: '仲村 ゆり — Yuri Nakamura — ' },
    { name: '入江美雪', originalName: '入江 みゆき — Miyuki Irie — ' },
    { name: '渕田久子', originalName: '渕田 ひさ子 — Hisako Fuchida — ' },
];

// 所有战术形态
export const tactics = [
    {
        name: '茅森月歌',
        tactic: [
            'Attack or Music',
            '戦場のフレット',
            'つかの間の安息',
            '閃光のサーキットバースト',
            '黎明のエモーショナル・ソウル',
            '残響のカルディナル',
            'ナイトクルーズ・エスコート',
            'The Feel of the Throne',
        ],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '和泉由希',
        tactic: [
            'Attack or March',
            '夢幻のSleeping Ocelot',
            'KETSUの休日',
            '終いのSpitfire',
            '夏椿、けうらなる夜光星',
            'ナイトクルーズ・アテンダント',
            '踊るリンカネーション',
            '君を待つ紅玉',
        ],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '逢川惠',
        tactic: [
            'Plain or Wild',
            'Impact Stream',
            'Ikki Burst Strike',
            '一夜の夢',
            '心、躍るFuel',
            'トワイライト・グロース',
        ],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown', 'DefenseDown', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '东城司',
        tactic: [
            'Serious or Stupid',
            '嗟歎のスリーパー',
            'メメント・モリの美少女',
            '真夏のPrayer',
            'シークレットサービス・サイレンス',
        ],
        hojuCategory: ['-', 'AttackUp', 'AttackUp', 'AttackUp', 'AttackUp'],
    },
    {
        name: '朝仓可怜',
        tactic: [
            'Laugh or Cry',
            '最前線のブルワーク',
            '狂悖暴戻のランダムウォーク',
            '紅蓮月華のKillrazor',
            'スカーレット・リベリオン',
            'シークレットサービス・デモリッシュ',
        ],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '国见玉',
        tactic: [
            'Truth or Lies',
            'ときめきアークライト',
            '無邪気なデザイア',
            '気合一閃エンジェルセイラー',
            '魔法の国のエレメンタル',
            '激突！！エア・ベース',
            '幻想のコーラル',
            'トワイライト・メモリーズ',
        ],
        hojuCategory: ['-', '-', '-', '-', '-', 'AttackUp', '-', 'AttackUp'],
    },
    {
        name: '苍井绘梨花',
        tactic: ['戦場の花散らし', 'セツナ・memory', 'ココロ・Inspire', 'トドケ・Miracle', 'キララ・究極のアイドル'],
        hojuCategory: ['-', '-', '-', '-', '-'],
    },
    {
        name: '水濑莓',
        tactic: ['戦場の華火', 'あなたのために', '嬉々迫るフォール・イン・ラヴ', '君の瞳にコロしてる'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '水濑李',
        tactic: ['戦場の焔焔', '積乱雲', '類は友を呼ぶ', '残光', '茹だるアサシン'],
        hojuCategory: ['-', 'Attack', 'AttackUp', 'Attack', 'Attack'],
    },
    {
        name: '樋口圣华',
        tactic: ['戦場の科学者', '或る少女の物語', '青春の発露', '生者のホメオスタシス', '宙の探究、星の眩耀'],
        hojuCategory: ['-', 'AttackUp', 'Attack', 'AttackUp', 'AttackUp'],
    },
    {
        name: '柊木梢',
        tactic: ['戦場の聳動', '在りし日の雑踏', '蒼きノクターン', '終劇のナイトフォール', 'ホップ・ステップ・スリップ！'],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown', 'DefenseDown', '-'],
    },
    {
        name: '白虎',
        tactic: ['戦場の哮り', 'リラックスサイン', 'レイジング・ビースト', 'のもけいしさや'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '山胁·波昂·伊瓦尔',
        tactic: ['我が道を行く', '魔王の帰還', '山脇様、ご乱心', 'Ebon Knight', 'Holy Knight', 'Daydream Believer'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '樱庭星罗',
        tactic: [
            'クリスタルの導き',
            '雨模様、心そうそう',
            '今日のあなたの運勢は？',
            '星の海、たゆたうフォーチュンテラー',
            '対決！！エア・ステージ',
        ],
        hojuCategory: ['-', '-', 'DefenseDown', '-', 'AttackUp'],
    },
    {
        name: '天音巫呼',
        tactic: [
            '悠久渇望の魔術師',
            '夢浮橋辿る赤',
            'エクスペリメンタルなキミ',
            '山脇様の手下：マジカルにゃん',
            '至高のひととき',
        ],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '丰后弥生',
        tactic: [
            'カニ手の使い魔',
            '手のひらのパーフェクション',
            '夜空のShining Star',
            'Happy Legion',
            'お花見怪人クシダンゴ',
        ],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '神崎·阿黛尔海德',
        tactic: ['碧落の忍び', '微光の兆し', 'ごちゃまぜ忍法大乱闘', '氷花のHexerei', '少女の休息'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '佐月麻里',
        tactic: ['ビジネスとスマイル', 'はにかむ、心かき集め', '甘美のMuzzle', 'アサシン忍法大繁盛', 'Crying Tears'],
        hojuCategory: ['-', 'AttackUp', 'AttackUp', 'AttackUp', 'Attack'],
    },
    {
        name: '白河结奈',
        tactic: ['Ally', 'Sign', '君がいるだけで', 'Awakening Iris', 'Infernal Sanctuary', '真夏のジャンダルム'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '月城最中',
        tactic: ['禅定、憂い無し', '黄昏、久遠の夢', '真宵晴るれば、一閃心静', '掩蔽された暇'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '桐生美也',
        tactic: ['幽幻閑寂いとらうたし', '神祠見舞う儚さかな', '星林遣らずの雨', '豊楽ノ神秘'],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '菅原千惠',
        tactic: ['ドロレスの魅惑', '気まぐれのアンニュイ', 'センパイの品格', '終末ロリータ白書', '亡国の純心'],
        hojuCategory: ['-', '-', '-', '-', '-'],
    },
    {
        name: '小笠原绯雨',
        tactic: ['仄かに揺らぐファイア', '静寂閑雅のレスト', '朧月夜のバレット', '萌えよ天才剣士', '希求と渇仰'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '藏里见',
        tactic: ['きおい佳人', '清流に坐す', '夜警の空', '此に期するは豊穣の御霊', '若女将の日々'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '二阶堂三乡',
        tactic: ['最年少名人', '今昔の感、想いは往きて', '無上の終局', 'Holiday Ring a Bell'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '石井色叶',
        tactic: ['お気楽カラフル', '多彩なるインスピレーション', 'ピュア・エモーション', '撃砕の無彩色'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '命吹雪',
        tactic: ['Metal Crazy', 'Silent Reverberations', '終末なにする？'],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '室伏理沙',
        tactic: [
            '親愛のレシーバー',
            '痛いの痛いの飛んでけ〜♪',
            'あまいろの花',
            '早くおうちに帰りましょ',
            '潜入、笑顔で技術交流会',
        ],
        hojuCategory: ['-', '-', '-', '-', '-'],
    },
    {
        name: '伊达朱里',
        tactic: ['ネガティブエース', 'マーベルアプローチ', 'テニスコートの悪魔', 'Holiday Star Night'],
        hojuCategory: ['-', 'AttackUp', 'AttackUp', 'AttackUp'],
    },
    {
        name: '瑞原蓝娜',
        tactic: ['深き海の学び手', 'アビスからの誘い', '奥深い味わいを', 'ロックアップオルカ'],
        hojuCategory: ['-', 'DefenseDown', 'Attack', 'DefenseDown'],
    },
    {
        name: '大岛一千子',
        tactic: ['一千子の抱擁', '同床異夢明けて', '果てなき慈愛の守護者', 'Sweet Phantasy'],
        hojuCategory: ['-', '-', 'AttackUp', '-'],
    },
    {
        name: '大岛二以奈',
        tactic: ['二以奈の気品', '戦線キャットウォーク', '戸惑いの波際', 'Brand New Mind', '心緒、昂る温泉郷'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '大岛三野里',
        tactic: ['三野里の疾走', '韋駄天配達人', 'Realize Your Mind'],
        hojuCategory: ['-', 'Attack', 'Attack'],
    },
    {
        name: '大岛四叶草',
        tactic: ['四ツ葉の倦怠', 'Lollipop Break', '破られたアンニュイ', 'ぐうたらパジャマナイト'],
        hojuCategory: ['-', 'AttackUp', 'AttackUp', 'AttackUp'],
    },
    {
        name: '大岛五十铃',
        tactic: ['五十鈴の秘密', 'ピースフルフレーバー', '勝利への鍵', '湯上がり夢現郷'],
        hojuCategory: ['-', 'DefenseDown', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '大岛六宇亚',
        tactic: ['六宇亜の陶酔', '疾走エクスタシー', 'グルーヴィーなロール', 'ピンチで最高', '早春向かい風'],
        hojuCategory: ['-', '-', '-', '-', '-'],
    },
    {
        name: '柳美音',
        tactic: ['ロイヤルバトラー', '滅私奉公のトラスト', 'Wild Rose', '夜風のChill Time'],
        hojuCategory: ['-', '-', '-', '-'],
    },
    {
        name: '丸山奏多',
        tactic: ['リトルハイセンス', 'お嬢様は端麗', '木漏れ日の庭', 'エボリューションな感受性'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '华村诗纪',
        tactic: ['アバンチュールコンダクター', '木漏れ日のソナタ', '君のUnisono'],
        hojuCategory: ['-', 'AttackUp', 'AttackUp'],
    },
    {
        name: '松岗绮罗路',
        tactic: ['天才かませ犬', '秘めたる努力', '疾風迅速滅亡の狼煙'],
        hojuCategory: ['-', '-', 'Attack'],
    },
    {
        name: '夏目祈',
        tactic: ['天誅斬影', '彼岸の人斬り', '剣の冷徹', '薫衣香る夢見鳥'],
        hojuCategory: ['-', 'Attack', 'Attack', 'Attack'],
    },
    {
        name: '黑泽真希',
        tactic: ['Highway of Angels', '唯我独尊', 'ハレの日の仏恥義理'],
        hojuCategory: ['-', 'Attack', 'Attack'],
    },
    {
        name: '卡罗尔·利帕',
        tactic: ['星の継承者', '心の風景', '摩天楼のダークヒーロー'],
        hojuCategory: ['-', 'Attack', 'Attack'],
    },
    {
        name: '李映夏',
        tactic: ['臥龍の代弁者', '武を成すは天に在り', '我、勇ならざるは将なきに同じ', 'いざなうつゆくさ'],
        hojuCategory: ['-', 'AttackUp', 'AttackUp', 'AttackUp'],
    },
    {
        name: '艾琳·雷德梅因',
        tactic: ['不可思議の探求者', 'ベイカー街の軌跡', '碧いカーヴァンクル'],
        hojuCategory: ['-', 'Attack', 'Attack'],
    },
    {
        name: '芙里提卡·巴拉克里希南',
        tactic: ['華麗なるアプサラス', '未知数なヴェーダーンガ', '華麗なる日常', '凛々しきドゥルガー'],
        hojuCategory: ['-', '-', '-', 'DefenseDown'],
    },
    {
        name: '玛丽亚·黛·安杰利斯',
        tactic: ['くれないのシスター', '罪と栄光', 'ブラッド・レリーフ', '刹那の邂逅'],
        hojuCategory: ['-', '-', 'Attack', 'Attack'],
    },
    {
        name: '夏洛特·斯可波夫斯加',
        tactic: ['北風の使者', '雪華ノスタルジア', '逆境に咲く華', 'とこしえの想い'],
        hojuCategory: ['-', 'AttackUp', 'DefenseDown', 'DefenseDown'],
    },
    {
        name: '立华奏',
        tactic: ['Earth Angel', '天翔ける剣'],
        hojuCategory: ['Attack', 'Attack'],
    },
    {
        name: '仲村百合',
        tactic: ['Rain Fire', 'ありふれた非日常'],
        hojuCategory: ['Attack', 'Attack'],
    },
    {
        name: '入江美雪',
        tactic: ['Pure Cosmos', 'Faraway Eden'],
        hojuCategory: ['-', '-'],
    },
    {
        name: '渕田久子',
        tactic: ['Finally found our silver lining'],
        hojuCategory: ['DefenseDown'],
    },
];
