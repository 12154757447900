import { Modal, List } from 'antd';

const data = [
    '增加新战术形态、技能数据（梢、李）',
    '增加新战术形态、技能数据（天音、丰后、星罗）',
    '增加月歌王的技能数据',
    '增加2nd Anniversary人物、技能数据',
    '增加Angel Beats 2期人物、技能数据',
    '修复了一些bug',
    'v1.0-alpha版本上线',
    '基本功能完成',
];

const updateDate = ['2024-03-25', '2024-03-08', '2024-02-25', '2024-02-18', '2024-02-05', '2024-01-26', '2024-01-24', '2024-01-20'];

const GlobalModal = props => {
    const { isOpen, handleModalClose } = props;
    return (
        <Modal centered width={800} title="工具简介" open={isOpen} footer={null} onCancel={handleModalClose}>
            <List
                size="small"
                header={<div>更新记录</div>}
                bordered
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <p>{updateDate[index]}</p>
                        {item}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default GlobalModal;
