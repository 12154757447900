import React, { useLayoutEffect, useRef } from 'react';
import { Card, InputNumber, Divider, message } from 'antd';
import styles from './index.module.scss';

const getBuffName = (name, isPassiveSkill, elements) => {
    const imgList = ['Dark', 'Fire', 'Ice', 'Light', 'Thunder', 'Slash', 'Stab', 'Strike'];
    const imgs = elements?.map((el, index) =>
        imgList.includes(el) ? (
            <img src={require(`../../assets/${el}.webp`)} alt={el} key={index} width="16px" height="16px" />
        ) : (
            `(${el})`
        )
    );
    return (
        <span className={styles.buff_name}>
            <span className={styles.name}>{`${name}${isPassiveSkill ? '(被动)' : ''}`}</span>
            {imgs}
        </span>
    );
};

// title                标题
// buffSkills           所有增益技能(isBuffSkill === true),比 skills 表中多了具体增益值(value)
// allValue             本乘区总倍率
// counts               每个技能叠加次数数组
// handleInputChange    输入框回调
const BuffCard = props => {
    const { title = '', buffSkills, allValue, counts, handleInputChange } = props;
    const ref = useRef();

    // 瀑布流布局
    useLayoutEffect(() => {
        if (ref.current) {
            const el = ref.current;
            const rows = Math.ceil(el.clientHeight / 5) + 1;
            el.style.gridRowEnd = `span ${rows}`;
        }
    });

    const renderMainDivider = (info, index, arr) => {
        if (index === 0 || info.memberName !== arr[index - 1].memberName) {
            const text = info.memberName || '其他';
            return <Divider>{text}</Divider>;
        }
    };

    // 渲染所有项
    const getItems = () => {
        return buffSkills.map((info, index, arr) => {
            const { name, min = 0, max = 999, buffParts, value, isPassiveSkill } = info;
            if (buffParts && buffParts.length !== 1) {
                console.log(info);
                message.error('buffparts is wrong...');
            }
            // 技能属性
            const elements = isNaN(value) ? buffParts[0].elements : [];
            // 最大叠加次数
            const maxCount = isNaN(value) ? buffParts[0].maxCount : name === 'Token' ? 10 : 1;

            const otherLength = arr.filter(skill => !skill.memberName).length;

            const buffName = getBuffName(name, isPassiveSkill, elements);
            const selectedLineClassName = `${
                counts[index] * (isNaN(value) ? buffParts[0].value : value) ? styles.selected_line : ''
            }`;

            return (
                <div key={index}>
                    {renderMainDivider(info, index, arr)}
                    <div className={`${styles.line} ${selectedLineClassName}`}>
                        <InputNumber
                            addonBefore={buffName}
                            addonAfter="%"
                            controls
                            min={min}
                            max={max}
                            value={isNaN(value) ? buffParts[0].value : value}
                            onChange={value => handleInputChange(value, index, 0)}
                            disabled={index < arr.length - otherLength}
                            precision={index < arr.length - otherLength ? 1 : 0}
                        />
                        <div>×</div>
                        <InputNumber
                            controls
                            min={0}
                            max={maxCount}
                            value={counts[index]}
                            onChange={value => handleInputChange(value, index, 1)}
                            precision={0}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <Card className={styles.card} ref={ref}>
            <p>
                {title} {allValue.toFixed(2)}%
            </p>
            {getItems()}
        </Card>
    );
};

export default BuffCard;
