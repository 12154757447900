/**
 * 根据依存、能力值，计算 power 值
 *
 * @param {*} iZon
 * @param {*} abilities
 * @returns
 */
export const getPower = (iZon, abilities) => {
    const arr = ['str', 'dex', 'con', 'spr', 'wis', 'luk'];
    const newIzon = Object.entries(iZon).sort((a, b) => arr.indexOf(a[0]) - arr.indexOf(b[0]));
    const values = Object.values(newIzon);
    const power =
        values.reduce((pre, cur, index) => pre + cur[1] * abilities[index], 0) /
        values.reduce((pre, cur) => pre + cur[1], 0);

    return power;
};

/**
 * 根据技能值、技能等级，计算实际技能 buff 值
 *
 * @param {*} min
 * @param {*} max
 * @param {*} skillLevel
 * @param {*} growth
 * @returns
 */
export const getRealValue = (min, max, skillLevel, growth) => {
    const [left, right] = growth;
    const realMin = min * (1 + left * (skillLevel - 1)),
        realMax = max * (1 + right * (skillLevel - 1));
    return [realMin, realMax];
};

/**
 * 根据技能值、阈值、power、技能类型、border、funnelValue，计算实际 Buff 值
 *
 * @param {*} min
 * @param {*} max
 * @param {*} threshold
 * @param {*} power
 * @param {*} catagory
 * @param {*} border
 * @param {*} funnelValue
 * @returns
 */
export const getBuffValue = (min, max, threshold, power, category, border, funnelValue = 4, isCrit = false) => {
    let value = 0;
    if (min === max) {
        if (category === 'Funnel') {
            // 计算连击增益
            value = (min / 100) * funnelValue;
        } else {
            value = min;
        }
    } else {
        // 减益类技能，sd 需要考虑 border
        let sd = category === 'DefenseDown' || category === 'Fragile' || category === 'Attack' ? power - border : power;
        if (isCrit) {
            sd += 50;
        }
        if (category === 'Attack' && sd <= 0) {
            if (sd <= -0.5 * threshold) {
                value = 1;
            } else if (sd > -0.5 * threshold && sd <= 0) {
                value = min - min * ((-1 * sd) / (threshold * 0.5));
            }
        } else if (sd <= 0) {
            value = min;
        } else if (sd >= threshold) {
            value = max;
        } else {
            value = min + (max - min) * (sd / threshold);
        }
    }
    return value;
};
