import React from 'react';
import { Select, Slider } from 'antd';
import styles from './index.module.scss';

const UseSkillAndEnemy = props => {
    const {
        skills,
        selectedMembers,
        atkSkill,
        atkMember,
        border,
        breakNum,
        handleMemberChange,
        handleSkillChange,
        handleBorderChange,
        handleBreakNumChange,
    } = props;

    return (
        <div className={styles.content}>
            <p className={styles.title}>选择攻击技能</p>
            <Select
                className={styles.select}
                placeholder="选择人物"
                options={selectedMembers.map(member => ({ label: member, value: member }))}
                value={atkMember}
                onChange={handleMemberChange}
                disabled={selectedMembers.length === 0}
            />
            <Select
                className={styles.select}
                placeholder="选择攻击技能"
                options={skills
                    .filter(skill => skill.memberName === atkMember)
                    .map(skill => ({ label: skill.name, value: skill.name }))}
                value={atkSkill}
                onChange={handleSkillChange}
                disabled={!atkMember}
            />
            {/* {JSON.stringify(skills.filter(skill => skill.name === atkSkill))} */}
            <p className={styles.title}>设置敌人数据</p>
            <p className={styles.subtitle}>Border：{border}</p>
            <Slider min={0} max={500} value={border} onChange={handleBorderChange} />
            <p className={styles.subtitle}>破坏率：{breakNum}</p>
            <Slider min={100} max={999} value={breakNum} onChange={handleBreakNumChange} />
        </div>
    );
};

export default UseSkillAndEnemy;
