import React, { useState } from 'react';
import { InputNumber, Button, Modal, Select } from 'antd';
import styles from './index.module.scss';
import { tactics } from '../../data/data';
import { skills } from '../../data/skills';

// name                 队员名
// abilities            当前队员七维数组
// memberIndex          当前队员 index
// levels               当前队员技能等级数组
// tactic               当前战术形态
const MemberCard = props => {
    const { name, abilities, memberIndex, levels, tactic, handleInputChange, handleLevelChange, handleTacticChange } =
        props;

    const options = tactics
        .find(tactic => tactic.name === name)
        .tactic.map(tactic => ({ label: tactic, value: tactic }));

    const [isOpen, setIsOpen] = useState(false);

    // 当前队员的所有非被动技能
    const allSkills = skills.filter(skill => skill.memberName === name && !skill.isPassiveSkill);

    // 渲染七维
    const getInputs = () => {
        const titles = ['力量', '灵巧', '体力', '精神', '智力', '运气', '宝珠'];
        return abilities.map((ability, index) => {
            return (
                <InputNumber
                    className={styles.ability}
                    addonBefore={titles[index]}
                    controls
                    min={0}
                    max={index === 6 ? 5 : 999}
                    precision={0}
                    value={ability}
                    onChange={val => handleInputChange(val, index, memberIndex)}
                    key={index}
                />
            );
        });
    };

    // modal 打开
    const handleModalOpen = () => {
        setIsOpen(true);
    };

    // modal 关闭
    const handleModalClose = () => {
        setIsOpen(false);
    };

    // 渲染技能等级
    const getLevels = () => {
        return allSkills
            .map((skill, index) => {
                const { name, level } = skill;
                const [min, max] = level;
                return (
                    <InputNumber
                        className={styles.input_levels}
                        key={index}
                        addonBefore={name}
                        controls
                        min={min}
                        max={max}
                        value={levels[index] || 10}
                        onChange={value => handleLevelChange(value, memberIndex, index)}
                        precision={0}
                    />
                );
            });
    };

    return (
        <div className={styles.card}>
            <p>{name}</p>
            <Select
                className={styles.select}
                options={options}
                placeholder="选择战术形态"
                value={tactic}
                onChange={value => handleTacticChange(value, memberIndex)}
            />
            <Button className={styles.btn} type="primary" onClick={handleModalOpen}>
                设定技能等级
            </Button>
            {getInputs()}
            <Modal centered width={300} title="技能等级" open={isOpen} footer={null} onCancel={handleModalClose}>
                {getLevels()}
            </Modal>
        </div>
    );
};

export default MemberCard;
